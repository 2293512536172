import React from 'react'
import UnderDevelopment from '../../components/UnderDevelopment'

const SlideCircuiPro = () => {
  return (
    <>
      <UnderDevelopment/>
    </>
  )
}

export default SlideCircuiPro