import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold text-primary-500 dark:text-primary-400">
          Privacy Policy for SlideCircuitPro Software
        </h1>
        <p className="mt-4 text-lg dark:text-text-300">
          Effective Date: 02-Oct-2023
        </p>
      </header>
      <div className="max-w-6xl w-full">
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            1. Introduction
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            This Privacy Policy (“Policy”) outlines the practices of Compcarta
            Solutions Private Limited (“Compcarta,” “we,” “us”) concerning the
            collection, use, and protection of personal information when you
            (“User,” “Customer”) use the SlideCircuitPro software (“Software”).
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            2. Information We Collect
          </h2>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            2.1 Personal Information
          </h3>
          <ul className="list-disc list-inside mt-2 space-y-2 text-text-700 dark:text-text-300">
            <li>Name</li>
            <li>Email address</li>
            <li>Contact information</li>
            <li>Company name</li>
            <li>Payment information</li>
          </ul>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            2.2 Technical Information
          </h3>
          <ul className="list-disc list-inside mt-2 space-y-2 text-text-700 dark:text-text-300">
            <li>Device information (e.g., device type, operating system)</li>
            <li>Log data (e.g., IP address, access times)</li>
            <li>Usage data (e.g., features used within the Software)</li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            3. How We Use Your Information
          </h2>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            3.1 Providing Services
          </h3>
          <ul className="list-disc list-inside mt-2 space-y-2 text-text-700 dark:text-text-300">
            <li>To provide access to and usage of the Software.</li>
            <li>To communicate with you regarding your account, updates, and support.</li>
          </ul>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            3.2 Improving Our Services
          </h3>
          <ul className="list-disc list-inside mt-2 space-y-2 text-text-700 dark:text-text-300">
            <li>To analyze usage patterns and trends.</li>
            <li>To enhance and improve the functionality of the Software.</li>
          </ul>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            3.3 Payment Processing
          </h3>
          <ul className="list-disc list-inside mt-2 space-y-2 text-text-700 dark:text-text-300">
            <li>If applicable, to process payment transactions.</li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            4. Data Security
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            We take reasonable steps to protect your information from
            unauthorized access, use, or disclosure. However, no method of
            transmission over the Internet or electronic storage is entirely
            secure.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            5. Data Retention
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            We retain your personal information for as long as necessary to
            fulfill the purposes outlined in this Policy or as required by
            applicable laws.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            6. Sharing Your Information
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            We do not sell, trade, or rent your personal information to third
            parties. However, we may share your information with trusted service
            providers who assist us in providing and improving our services.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            7. Your Choices
          </h2>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            7.1 Access and Update Your Information
          </h3>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Access, correct, or update your personal information through your
            user account settings.
          </p>
          <h3 className="text-xl font-semibold mt-4 text-text-900 dark:text-text-200">
            7.2 Opt-Out
          </h3>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            You can opt-out of non-essential email communications from us.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            8. International Data Transfer
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Your information may be transferred to and processed in countries
            other than your own, where data protection laws may differ.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            9. Children’s Privacy
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            The Software is not intended for children under the age of 13. We do
            not knowingly collect personal information from children.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            10. Changes to this Policy
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            We may update this Policy from time to time. The updated Policy will
            be posted on our website, and the date of the latest revision will
            be indicated.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            11. Contact Us
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            If you have questions or concerns about this Policy or your personal
            information, please contact us at
          </p>
          <div className="font-semibold">
            <p className="mt-4 text-justify text-text-700 dark:text-text-300">
              Email:{" "}
              <a
                href="mailto:Info@compcarta.com"
                className="text-accent-500 dark:text-accent-400 underline"
              >
                info@compcarta.com
              </a>
            </p>
            <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Compcarta Solutions Private Limited
              <br />
              A-50, Ecotech - VI, Greater Noida, U.P., 201310,
              <br />
              02 Oct, 2023
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
