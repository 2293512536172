const OrdersContent = () => {
  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Your Orders</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b text-left">Order Number</th>
              <th className="py-2 px-4 border-b text-left">Date</th>
              <th className="py-2 px-4 border-b text-left">Status</th>
              <th className="py-2 px-4 border-b text-left">Total</th>
              <th className="py-2 px-4 border-b"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-4 border-b">12345</td>
              <td className="py-2 px-4 border-b">August 10, 2024</td>
              <td className="py-2 px-4 border-b">Shipped</td>
              <td className="py-2 px-4 border-b">Rs 99.99</td>
              <td className="py-2 px-4 border-b text-blue-500 hover:underline cursor-pointer">
                View Details
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrdersContent;
