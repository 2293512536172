import React from "react";
import { Link } from "react-router-dom";

const ProductsCard = ({ title, Icon, path, description, color, hover }) => {
  return (
    <div className="flex flex-col text-sm space-y-8 md:space-y-0 md:space-x-8">
      <Link
        to={path}
        target="_blank"
        className="group relative overflow-hidden rounded-lg bg-card p-6 shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl"
      >
        <div
          className={`absolute inset-0 z-0 bg-gradient-to-r  ${color} opacity-20 blur-xl group-hover:opacity-40`}
        />
        <div className="relative z-10 flex flex-col items-center justify-center gap-4">
          <div
            className={`flex h-16 w-16 items-center justify-center rounded-full bg-gray-900 p-4 shadow-lg transition-all duration-300 ${hover} group-hover:text-gray-900`}
          >
            <Icon className="h-8 w-8 text-gray-500 group-hover:text-gray-900 hover:scale-125 transition-transform duration-500" />
          </div>
          <h3 className="text-xl font-semibold">{title}</h3>
          <p className="text-muted-foreground">{description}</p>
        </div>
      </Link>
    </div>
  );
};

export default ProductsCard;
