import { FaDownload } from "react-icons/fa";

 const DownloadsContent = () => {
    return (
      <div className="p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Your Downloads</h2>
        <div className="flex justify-between items-center mb-4">
          <div>
            <select className="p-2 border rounded-md">
              <option>All Products</option>
              <option>Software</option>
              <option>eBooks</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              placeholder="Search Downloads..."
              className="p-2 border rounded-md"
            />
          </div>
        </div>
        <div className="bg-gray-50 p-4 border rounded">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <FaDownload className="mr-2 text-blue-500" />
              <span>CircuitPro - Version 1.0.0.0</span>
            </div>
            <button className="text-blue-500 hover:underline">Download</button>
          </div>
        </div>
        {/* Add more download items as needed */}
      </div>
    );
  };
  
  export default DownloadsContent