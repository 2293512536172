import React from "react";

const CancellationAndRefundPolicy = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold text-primary-500 dark:text-primary-400">
          Cancellation and Refund Policy
        </h1>
      </header>
      <div className="max-w-6xl w-full">
        <section className="mt-8">
          <p className="mt-4 text-text-700 dark:text-text-300 text-justify">
            This Cancellation and Refund Policy (“Policy”) outlines the terms
            and conditions under which Compcarta Solutions Private Limited
            (“Compcarta,” “we,” “us”) processes cancellations and refunds for
            the SlideCircuitPro software (“Software”) on both monthly and yearly
            subscription plans.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">1. Cancellation</h2>
          <div className="mt-4">
            <h3 className="text-xl font-semibold">1.1 Monthly Subscription Plan:</h3>
            <ul className="list-disc list-inside ml-4 text-text-700 dark:text-text-300">
              <li>Users with a monthly subscription plan may cancel their subscription at any time during the subscription period.</li>
              <li>Cancellation requests can be made by contacting our customer support team as per the contact information defined below.</li>
              <li>Upon cancellation, access to the Software will be terminated at the end of the current monthly billing cycle.</li>
              <li>Monthly subscription fees are non-refundable.</li>
            </ul>
          </div>
          <div className="mt-4">
            <h3 className="text-xl font-semibold">1.2 Yearly Subscription Plan:</h3>
            <ul className="list-disc list-inside ml-4 text-text-700 dark:text-text-300">
              <li>Users with a yearly subscription plan may cancel their subscription at any time during the subscription period.</li>
              <li>Cancellation requests can be made by contacting our customer support team as per the contact information defined below.</li>
              <li>Upon cancellation, access to the Software will be terminated at the end of the current yearly billing cycle.</li>
            </ul>
          </div>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">2. Refund</h2>
          <div className="mt-4">
            <h3 className="text-xl font-semibold">2.1 Monthly Subscription Plan:</h3>
            <p className="mt-2 text-text-700 dark:text-text-300 text-justify">
              Monthly subscription fees are non-refundable, and no refunds will be provided for partial monthly subscription periods.
            </p>
          </div>
          <div className="mt-4">
            <h3 className="text-xl font-semibold">2.2 Yearly Subscription Plan:</h3>
            <p className="mt-2 text-text-700 dark:text-text-300 text-justify">
              Yearly subscription fees are non-refundable, and no refunds will be provided for partial yearly subscription periods.
            </p>
          </div>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">3. Contact Us</h2>
          <p className="mt-4 text-text-700 dark:text-text-300 text-justify">
            If you have questions or concerns regarding this Cancellation and Refund Policy, please contact our customer support team at:
          </p>
          <div className="font-semibold mt-4 text-justify">
            <p>
              Email:{" "}
              <a
                href="mailto:info@compcarta.com"
                className="text-accent-500 dark:text-accent-400 underline"
              >
                info@compcarta.com
              </a>
            </p>
            <p className="mt-4">
              Compcarta Solutions Pvt. Ltd.
              <br />
              A-50, Ecotech - VI, Greater Noida, U.P., 201310
              <br />
              02 Oct, 2023
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CancellationAndRefundPolicy;
