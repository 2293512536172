const blockedDomains = [
  'tempmail.com',
  '10minutemail.com',
];

const allowedDomains = [
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'compcarta.com',
  'gmail.com',
];

export const isAllowedEmail = (email) => {
  const domain = email.split('@')[1];

  if (blockedDomains.includes(domain)) {
    return false;
  }

  return allowedDomains.includes(domain);
};

  