import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Confirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    selectedFeatures,
    featureDates,
    totalPrice,
    customerName,
    customerEmail,
    paymentMethod,
    hostInfo,
  } = state;

  const handleConfirm = () => {
    navigate("/checkout", { state });
  };

  const handleModify = () => {
    navigate("/", { state });
  };

  return (
    <div className="flex pt-10 flex-col min-h-screen bg-gradient-to-br from-primary to-secondary text-primary-foreground">
      <main className="flex-1 p-8 md:p-12 lg:p-16 bg-gradient-to-br from-primary to-secondary">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold mb-6 text-primary-foreground">
            Confirmation
          </h1>
          <div className="bg-background bg-opacity-50 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold text-primary-foreground mb-4">
              Customer Details
            </h2>
            <p className="text-muted-foreground">Name: {customerName}</p>
            <p className="text-muted-foreground">Email: {customerEmail}</p>
            <p className="text-muted-foreground">Host Info: {hostInfo}</p>
            <h2 className="text-2xl font-semibold text-primary-foreground mt-6">
              Selected Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              {selectedFeatures.map((feature) => (
                <motion.div
                  key={feature.id}
                  className="border-2 p-4 bg-background bg-opacity-50 rounded-lg transition-transform transform hover:scale-105"
                >
                  <h3 className="text-lg font-semibold text-primary-foreground">
                    {feature.name}
                  </h3>
                  <p className="text-sm text-muted-foreground">
                    {feature.description}
                  </p>
                  <p className="text-muted-foreground">
                    Price: ₹{feature.price}
                  </p>
                  <p className="text-muted-foreground">
                    Start Date:{" "}
                    {featureDates[feature.id].startDate.toLocaleDateString()}
                  </p>
                  <p className="text-muted-foreground">
                    Expiry Date:{" "}
                    {featureDates[feature.id].expiryDate.toLocaleDateString()}
                  </p>
                  <p className="text-muted-foreground">
                    License Type: {featureDates[feature.id].licenseType}
                  </p>
                  <p className="text-muted-foreground">
                    Enabled: {featureDates[feature.id].enabled}
                  </p>
                </motion.div>
              ))}
            </div>
            <div className="mt-8">
              <h2 className="text-2xl font-semibold text-primary-foreground">
                Payment Method
              </h2>
              <p className="text-muted-foreground">Method: {paymentMethod}</p>
            </div>
            <div className="mt-8">
              <h2 className="text-2xl font-semibold text-primary-foreground">
                Total Price
              </h2>
              <p className="text-muted-foreground">₹{totalPrice}</p>
            </div>
            <div className="flex justify-end mt-8">
              <button
                onClick={handleModify}
                className="mr-4 px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-yellow-500 to-orange-500 text-white hover:bg-white hover:text-black hover:scale-105"
              >
                Modify
              </button>
              <button
                onClick={handleConfirm}
                className="px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-yellow-500 to-orange-500 text-white hover:bg-white hover:text-black hover:scale-105"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Confirmation;
