export const legalNotices = [
  {
    title: "Copyright Notice",
    content:
      "The Software, including all its components, features, documentation, and related materials, is protected by copyright laws. Compcarta Solutions Pvt Ltd is the sole and exclusive owner of all intellectual property rights, including copyrights, pertaining to the Software.",
  },
  {
    title: "Trademark Notice",
    content:
      "“SlideCircuitPro” is a registered trademark of Compcarta Solutions Pvt Ltd. The unauthorized use of this trademark is strictly prohibited and subject to legal action.",
  },
  {
    title: "License Agreement",
    content:
      "The use of SlideCircuitPro is subject to the terms and conditions of the End User License Agreement (EULA) provided with the Software. Any violation of the terms and conditions of the EULA may result in the termination of the license and legal action.",
  },
  {
    title: "Infringement Notification",
    content:
      "If you believe that the Software or any related materials infringe upon your intellectual property rights, please contact Compcarta Solutions Pvt Ltd’s legal department at [Contact Email/Address] with detailed information regarding the alleged infringement.",
  },
  {
    title: "Disclaimer",
    content:
      "Compcarta Solutions Pvt Ltd makes no representations or warranties, express or implied, regarding the functionality, accuracy, or performance of the Software. The Software is provided “as is,” and Compcarta Solutions Pvt Ltd shall not be liable for any damages, including but not limited to direct, indirect, special, or consequential damages, arising out of the use of the Software.",
  },
  {
    title: "Governing Law",
    content:
      "This Legal Notice is governed by and construed in accordance with the laws of [Gautam Buddha Nagar, Uttar Pradesh], and any legal action arising out of or related to the Software shall be brought in the courts of [Gautam Buddha Nagar, Uttar Pradesh].",
  },
];
