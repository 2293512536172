import { FaEnvelope, FaPhone } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const footerLinks = [
  {
    section: "Quick Links",
    links: [
      { name: "About us", url: "/company/about-us" },
      { name: "Careers", url: "/company/careers" },
      { name: "News & Articles", url: "/company/media" },
      { name: "Legal Notice", url: "/legal-notice" },
    ],
  },
  {
    section: "Other Pages",
    links: [
      {
        name: "Software License Agreement",
        url: "/software-license-agreement",
      },
      { name: "Shipping & Delivery", url: "/shipping-delivery" },
      { name: "Privacy Policy", url: "/privacy-policy" },
      { name: "Cancellation & Refund", url: "/cancellation-refund" },
      { name: "Contact Us", url: "/contact-us" },
      { name: "Terms and Conditions", url: "/terms-and-conditions" },
    ],
  },
];

const bottomLinks = [
  { name: "Privacy Policy", url: "/privacy-policy" },
  { name: "Terms & Conditions", url: "/terms-and-conditions" },
];

const contactDetails = [
  {
    icon: <FaPhone />,
    name: "Phone",
    data: "+91 9599471583",
    to: "tel:+91 9599471583",
  },
  {
    icon: <FaEnvelope />,
    name: "Mail",
    data: "info@compcarta.com",
    to: "mailto:info@compcarta.com",
  },
  {
    icon: <FaLocationDot />,
    name: "Address",
    data: "A-50, Ecotech-VI, Greater Noida, U.P, 201310",
    to: "https://maps.app.goo.gl/S7txhGg1EvRPpMH3A",
  },
];

const companyInfo = {
  name: "Compcarta Solutions Pvt. Ltd.",
  description:
    "Dedicated to transforming VLSI design with intelligent EDA tools that drive innovation and efficiency. Our solutions are crafted to reduce effort and complexity, empowering engineers to focus on what truly matters - creating groundbreaking technology.",
  linkedin: "https://www.linkedin.com/company/compcarta-solutions",
  copyright: `© ${new Date().getFullYear()} Compcarta Solutions Pvt. Ltd. All rights reserved.`,
};

export { footerLinks, bottomLinks, contactDetails, companyInfo };
