import React from "react";
import { useParams } from "react-router-dom";
import { FaCcMastercard, FaCcVisa, FaCcAmex, FaCcPaypal, FaLock, FaInfoCircle } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md";

const PaymentDashboard = () => {
  const { method } = useParams();

  return (
    <div className="flex pt-24 flex-col min-h-screen bg-gradient-to-br from-primary to-secondary text-primary-foreground">
      <main className="flex-1 p-8 md:p-12 lg:p-16 bg-gradient-to-br from-primary to-secondary">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold mb-6 text-primary-foreground">
            {method.charAt(0).toUpperCase() + method.slice(1)} Dashboard
          </h1>
          <section className="bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
            <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
              <div className="mx-auto max-w-5xl">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">Payment</h2>
                <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12">
                  <form className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6 lg:max-w-xl lg:p-8">
                    <div className="mb-6 grid grid-cols-2 gap-4">
                      <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="full_name" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"> Full name (as displayed on card)* </label>
                        <input type="text" id="full_name" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" placeholder="Bonnie Green" required />
                      </div>

                      <div className="col-span-2 sm:col-span-1">
                        <label htmlFor="card-number-input" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"> Card number* </label>
                        <input type="text" id="card-number-input" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 pe-10 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" placeholder="xxxx-xxxx-xxxx-xxxx" pattern="^4[0-9]{12}(?:[0-9]{3})?$" required />
                      </div>

                      <div>
                        <label htmlFor="card-expiration-input" className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">Card expiration* </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 start-0 flex items-center ps-3.5">
                            <FaLock className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                          </div>
                          <input id="card-expiration-input" type="text" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 ps-9 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500" placeholder="12/23" required />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="cvv-input" className="mb-2 flex items-center gap-1 text-sm font-medium text-gray-900 dark:text-white">
                          CVV*
                          <button data-tooltip-target="cvv-desc" data-tooltip-trigger="hover" className="text-gray-400 hover:text-gray-900 dark:text-gray-500 dark:hover:text-white">
                            <FaInfoCircle className="h-4 w-4" />
                          </button>
                          <div id="cvv-desc" role="tooltip" className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700">
                            The last 3 digits on back of card
                            <div className="tooltip-arrow" data-popper-arrow></div>
                          </div>
                        </label>
                        <input type="number" id="cvv-input" aria-describedby="helper-text-explanation" className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" placeholder="•••" required />
                      </div>
                    </div>
                    <button type="submit" className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Pay now</button>
                    <div className="mt-5 text-sm font-medium text-gray-500 dark:text-gray-400">
                      By confirming your subscription, you allow this site to charge your card for this payment and future payments in accordance with their terms.
                    </div>
                  </form>
                  <div className="mt-8 w-full lg:mt-0">
                    <h3 className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Billing information</h3>
                    <dl>
                      <dt className="mb-2 font-medium text-gray-900 dark:text-white">John Doe</dt>
                      <dd className="mb-4 text-gray-500 sm:mb-5 dark:text-gray-400"> 4556 NY, 34th 33rd Street, New York, United States </dd>
                    </dl>
                    <h3 className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">We accept</h3>
                    <ul className="mb-4 flex">
                      <li className="me-2">
                        <FaCcMastercard className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                      <li className="me-2">
                        <FaCcVisa className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                      <li className="me-2">
                        <FaCcAmex className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                      <li className="me-2">
                        <FaCcPaypal className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                    </ul>
                    <h3 className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Secured by</h3>
                    <ul className="flex">
                      <li className="me-2">
                        <FaLock className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                      <li className="me-2">
                        <MdVerifiedUser className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                      <li className="me-2">
                        <FaLock className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                      <li className="me-2">
                        <MdVerifiedUser className="w-14 text-gray-500 dark:text-gray-400" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default PaymentDashboard;
