import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Checkout = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const handlePayment = () => {
    // Handle payment processing logic here
    // Once payment is successful, navigate to receipt page
    navigate("/receipt", { state });
  };

  return (
    <div className="flex pt-10 flex-col min-h-screen bg-gradient-to-br from-primary to-secondary text-primary-foreground">
      <main className="flex-1 p-8 md:p-12 lg:p-16 bg-gradient-to-br from-primary to-secondary">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold mb-6 text-primary-foreground">
            Checkout
          </h1>
          <div className="bg-background bg-opacity-50 p-6 rounded-lg">
            <h2 className="text-2xl font-semibold text-primary-foreground mb-4">
              Payment Details
            </h2>
            {/* Display payment form details */}
            <button
              onClick={handlePayment}
              className="px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-yellow-500 to-orange-500 text-white hover:bg-white hover:text-black hover:scale-105"
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Checkout;
