import React from "react";
import { Link } from "react-router-dom";

const TermsAndConditions = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold text-primary-500 dark:text-primary-400 underline lg:pt-8">
          Terms and Conditions
        </h1>
        <p className="mt-4 text-lg font-semibold">
          Effective Date: 02-Oct-2023
        </p>
      </header>
      <div className="max-w-6xl w-full">
        <p className="mt-4 text-justify">
          These Terms and Conditions (“Terms”) govern the use of
          SlideCircuitPro, the software product provided by Compcarta Solutions
          Pvt Ltd (“the Company”). By using SlideCircuitPro, you agree to comply
          with these Terms. If you do not agree with these Terms, you may not
          use the software.
        </p>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">Contact Information:</h2>
          <p className="mt-4 text-justify">
            If you have any questions or concerns about these Terms or
            SlideCircuitPro, please contact us at:
          </p>
          <p className="mt-4 text-justify">Compcarta Solutions Pvt Ltd</p>
          <p className="mt-4 text-justify">
            Email:{" "}
            <Link
              to="mailto:info@compcarta.com"
              className="underline text-accent-500 dark:text-accent-400"
            >
              info@compcarta.com
            </Link>
          </p>
          <p className="mt-4 text-justify">
            Address: A-50, Ecotech - VI, Greater Noida, U.P., 201310
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            Limitation of Liability and Disclaimer of Warranties:
          </h2>
          <ul className="list-decimal list-outside space-y-4 pl-4">
            <li>
              <h3 className="mt-4 text-xl font-semibold text-primary-700 dark:text-primary-500">
                Limitation of Liability:
              </h3>
              <p className="mt-4 text-justify">
                The Company shall not be liable for any direct, indirect,
                special, incidental, consequential, or punitive damages, or any
                loss of profits or revenues, whether incurred directly or
                indirectly, arising out of the use or misuse of SlideCircuitPro.
              </p>
            </li>
            <li>
              <h3 className="mt-4 text-xl font-semibold text-primary-700 dark:text-primary-500">
                Disclaimer of Warranties:
              </h3>
              <p className="mt-4 text-justify">
                SlideCircuitPro is provided “as is” and “as available” without
                any warranties, either express or implied. The Company does not
                warrant that the software will be error-free or uninterrupted.
                The Company disclaims all warranties, including but not limited
                to, the implied warranties of merchantability and fitness for a
                particular purpose.
              </p>
            </li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">Rules of Conduct:</h2>
          <p className="mt-4 text-justify">
            When using SlideCircuitPro, you agree to abide by the following
            rules of conduct:
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2">
            <li>
              Compliance with Laws: You will use SlideCircuitPro in compliance
              with all applicable local, state, national, and international laws
              and regulations.
            </li>
            <li>
              Respect for Intellectual Property: You will not use
              SlideCircuitPro to infringe on the intellectual property rights of
              others, including but not limited to copyright and trademark
              rights.
            </li>
            <li>
              No Harmful Activities: You will not engage in any harmful
              activities, including distributing viruses, malware, or any other
              software that may cause harm to SlideCircuitPro or its users.
            </li>
            <li>
              No Unauthorized Access: You will not attempt to gain unauthorized
              access to SlideCircuitPro or its associated systems.
            </li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">User Restrictions:</h2>
          <ul className="list-decimal space-y-4 pl-4 mt-4">
            <li>
              <span className="mt-4 text-xl font-semibold">License:</span>{" "}
              <span className="mt-4 text-justify">
                You are granted a non-exclusive, non-transferable, and revocable
                license to use SlideCircuitPro, subject to License Terms and
                Conditions.
              </span>
            </li>
            <li>
              <span className="mt-4 text-xl font-semibold">User Responsibilities:</span>{" "}
              <span className="mt-4 text-justify">
                You are responsible for maintaining the confidentiality of your
                login information and ensuring that your use of SlideCircuitPro
                is in compliance with these Terms.
              </span>
            </li>
            <li>
              <span className="mt-4 text-xl font-semibold">Prohibited Actions:</span>{" "}
              <ul className="list-disc list-inside mt-4 space-y-2">
                <li>
                  Reverse engineering, decompiling, or attempting to derive the
                  source code of SlideCircuitPro.
                </li>
                <li>
                  Modifying, adapting, translating, or creating derivative works
                  based on SlideCircuitPro.
                </li>
                <li>
                  Distributing, licensing, or sublicensing SlideCircuitPro to
                  third parties.
                </li>
              </ul>
            </li>
            <li>
              <span className="mt-4 text-xl font-semibold">Termination:</span>{" "}
              <span className="mt-4 text-justify">
                The Company reserves the right to terminate your access to
                SlideCircuitPro if you violate these Terms. Termination is at
                the sole discretion of the Company.
              </span>
            </li>
          </ul>
        </section>
        <p className="mt-8 text-justify font-semibold">
          By using SlideCircuitPro, you agree to be bound by these Terms and any
          revisions that may be made to them in the future. The Company may
          update these Terms at any time, and it is your responsibility to
          review them periodically.
        </p>
        <p className="mt-4 text-justify font-semibold">
          If you have any questions or concerns about these Terms, please
          contact us at the provided contact information.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
