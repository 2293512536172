// ScrollIndicator.js

import React, { useEffect } from 'react';
import './ScrollIndicator.css'

const ScrollIndicator = () => {
  const handleScroll = () => {
    const winScroll = document.documentElement.scrollTop || document.body.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrolled = (winScroll / height) * 100;
    document.getElementById("scrollIndicator").style.width = scrolled + "%";
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div id="scrollIndicatorContainer">
      <div id="scrollIndicator" className='bg-gradient-to-r from-fuchsia-600 to-pink-600'></div>
    </div>
  );
};

export default ScrollIndicator;
