import React from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../redux/authActions";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/login");
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <div className="flex py-24 px-4 sm:px-0 flex-col min-h-screen  items-center justify-center bg-background-100 dark:bg-background-900">
      <div className="max-w-4xl mx-auto p-8 md:p-12 lg:p-16 border border-background-300 dark:border-background-800 rounded-lg flex flex-col items-center text-center bg-background-100 dark:bg-background-900">
        <h1 className="text-3xl text-center font-bold mb-6 text-text-900 dark:text-text-200">
          Logout
        </h1>
        <div className="flex flex-col items-center justify-center bg-background-200 dark:bg-background-800 bg-opacity-50 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold text-primary-500 dark:text-primary-400 mb-4">
            Logout Confirmation
          </h2>
          <p className="text-muted-foreground dark:text-text-400">
            Are you sure you want to logout?
          </p>
          <div className="flex space-x-4">
            <button
              onClick={handleLogout}
              className="mt-4 px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-red-500 to-pink-500 text-white hover:bg-accent-100 dark:hover:bg-accent-700 hover:scale-105"
            >
              Logout
            </button>
            <button
              onClick={handleCancel}
              className="mt-4 px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-gray-500 to-gray-700 text-white hover:bg-accent-100 dark:hover:bg-accent-700 hover:scale-105"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
