import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    setLoading(true);

    if (!newPassword) {
      setError("New password is missing.");
      setLoading(false);
      return;
    } else if (!confirmPassword) {
      setError("Confirm password is missing.");
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${apiURL}/api/auth/reset-password?token=${token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password: newPassword }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        setMessage(
          result.message ||
            "Password has been reset successfully. You will be redirected to the login page."
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError(
          result.message ||
            result.errors[0].msg ||
            "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      setError("Failed to connect to the server. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex px-4 sm:px-0 min-h-screen flex-col items-center justify-center bg-gradient-to-br from-primary-500 to-secondary-500 dark:from-primary-900 dark:to-secondary-900">
  <div className="mx-4 w-full max-w-md rounded-lg bg-background-50 dark:bg-background-800 p-8 shadow-xl sm:mx-0">
    <div className="mb-4 flex-col flex items-center justify-center gap-4">
      <img src="logo.png" width={200} height={200} alt="logo" />
    </div>
    <h2 className="text-center text-xl font-semibold text-text-900 dark:text-text-50">Reset Password</h2>
    <p className="text-sm text-center text-text-600 dark:text-text-200 mb-4">
      Please enter your new password to change.
    </p>
    <form className="space-y-4" onSubmit={handleResetPassword}>
      <div>
        <label htmlFor="newPassword" className="block text-sm font-medium text-text-900 dark:text-text-50">
          New Password
        </label>
        <input
          id="newPassword"
          type="password"
          placeholder="••••••••"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="mt-1 block w-full rounded-md border-background-300 dark:border-background-600 bg-background-50 dark:bg-background-700 text-text-900 dark:text-text-50 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400"
        />
      </div>
      <div>
        <label htmlFor="confirmPassword" className="block text-sm font-medium text-text-900 dark:text-text-50">
          Confirm New Password
        </label>
        <input
          id="confirmPassword"
          type="password"
          placeholder="••••••••"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="mt-1 block w-full rounded-md border-background-300 dark:border-background-600 bg-background-50 dark:bg-background-700 text-text-900 dark:text-text-50 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400"
        />
      </div>
      <button
        type="submit"
        className="w-full bg-gradient-to-br from-accent-500 to-accent-600 dark:from-accent-700 dark:to-accent-800 text-white dark:text-text-50 text-md font-semibold py-2 rounded-md shadow-sm hover:text-black dark:hover:text-text-900"
        disabled={loading}
      >
        {loading ? "Resetting..." : "Reset Password"}
      </button>
    </form>
    {error && (
      <p className="mt-4 text-center text-sm text-red-600 dark:text-red-400">{error}</p>
    )}
    {message && (
      <p className="mt-4 text-center text-sm text-green-600 dark:text-green-400">{message}</p>
    )}
  </div>
</div>

  );
};

export default ResetPassword;
