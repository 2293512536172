import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const user = useSelector((state) => state.auth.user);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const navigate = useNavigate();

  const handleResetPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="flex p-4 pt-24 sm:pt-16 flex-col min-h-screen bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <div className="flex-1 p-8 md:p-12 lg:p-16">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 text-primary-500 dark:text-primary-400">
            Settings
          </h1>
          <div className=" p-6 pl-0 rounded-lg">
            <h2 className="text-2xl font-semibold text-primary-700 dark:text-primary-500 mb-4">
              User Settings
            </h2>
            {user ? (
              <div className="mb-4">
                <h3 className="text-xl font-semibold text-primary-700 dark:text-primary-500">
                  Account Information
                </h3>
                <p className="text-text-700 dark:text-text-300">
                  Name: {user.firstName + " " + user.lastName}
                </p>
                <p className="text-text-700 dark:text-text-300">
                  Username: {user.username}
                </p>
                <p className="text-text-700 dark:text-text-300">
                  Email: {user.email}
                </p>
              </div>
            ) : (
              <div>
                <p className="text-text-700 dark:text-text-300">
                  Please log in first
                </p>
              </div>
            )}
            <div className="mb-4">
              <h3 className="text-xl font-semibold text-primary-700 dark:text-primary-500">
                Preferences
              </h3>
              <div className="flex items-center justify-between mb-4">
                <label className="text-text-700 dark:text-text-300">
                  Email Notifications
                </label>
                <input
                  type="checkbox"
                  checked={emailNotifications}
                  onChange={() => setEmailNotifications(!emailNotifications)}
                  className="form-checkbox h-5 w-5 text-primary-500 dark:text-primary-400"
                />
              </div>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-semibold text-primary-700 dark:text-primary-500">
                Security
              </h3>
              <button
                className="mt-4 px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-red-500 to-pink-500 dark:from-red-700 dark:to-pink-700 text-white dark:text-text-50 hover:bg-white hover:text-black hover:scale-105"
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
