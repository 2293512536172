import React, { useState } from "react";
import ContactCard from "../components/ContactCard";
import { FaUser, FaEnvelope, FaPhone, FaPaperPlane } from "react-icons/fa";
import axios from "axios";

const Contact = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [messageError, setMessageError] = useState(""); // Message length error state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Handle message length validation
    if (name === "message" && value.length < 10) {
      setMessageError("Message must be at least 10 characters long.");
    } else {
      setMessageError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true); // Set loading to true when starting the submission

    try {
      const token = localStorage.getItem("token"); // Retrieve the token
      const response = await axios.post(`${apiURL}/api/support`, formData, {
        headers: {
          "x-auth-token": token, // Set the token in the request headers
        },
      });

      if (response.status === 201) {
        setSuccess(
          response.data.message +
            ". " +
            `Your ticket id: ${response.data.ticketId}`
        );
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        });
      }
    } catch (err) {
      if (err.response) {
        setError(
          err.response.data.error || "An error occurred. Please try again."
        );
      } else if (err.request) {
        setError("No response from the server. Please check your connection.");
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  return (
    <div className=" bg-background-100 flex flex-col items-center justify-center dark:bg-background-900 mx-auto py-24 p-4">
      <div className="text-center px-6">
        <h2 className="text-4xl font-extrabold text-primary-900 dark:text-primary-100">
          Contact Us
        </h2>
        <p className="text-2xl text-justify font-semibold text-secondary-700 dark:text-secondary-200 mt-4">
          Have some big idea or brand to develop and need help?
        </p>
      </div>

      <div className="grid lg:grid-cols-3 max-w-5xl max-lg:max-w-3xl items-start gap-4 p-2 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-lg ring-1 ring-accent-500  hover:ring-accent-400 mt-12">
        <div className="rounded-lg p-6 space-y-8 h-full max-lg:order-1 bg-background-100 dark:bg-background-900">
          <h2 className="text-xl text-primary-800 dark:text-primary-200">
            Contact Information
          </h2>
          <p className="text-sm text-secondary-700 dark:text-secondary-300 mt-4">
            Have some big idea or brand to develop and need help?
          </p>
          <div className="flex flex-col gap-4 items-start text-gray-600 hover:text-gray-800 dark:text-background-100">
            <ContactCard />
          </div>
        </div>

        <div className="p-4 lg:col-span-2 bg-background-100 dark:bg-background-900 rounded-lg">
          <form onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-2 gap-8">
              <div className="relative flex items-center">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name*"
                  className="px-2 py-3 bg-transparent ring-1 ring-gray-200 dark:ring-gray-600 w-full rounded-lg text-sm text-primary-900 dark:text-primary-100 hover:ring-accent-500 focus:outline-none"
                  required
                />
                <FaUser className="absolute right-2 text-gray-400" />
              </div>

              <div className="relative flex items-center">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name*"
                  className="px-2 py-3 bg-transparent ring-1 ring-gray-200 dark:ring-gray-600 w-full rounded-lg text-sm text-primary-900 dark:text-primary-100 hover:ring-accent-500 focus:outline-none"
                  required
                />
                <FaUser className="absolute right-2 text-gray-400" />
              </div>

              <div className="relative flex items-center">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email*"
                  className="px-2 py-3 bg-transparent ring-1 ring-gray-200 dark:ring-gray-600 w-full rounded-lg text-sm text-primary-900 dark:text-primary-100 hover:ring-accent-500 focus:outline-none"
                  required
                />
                <FaEnvelope className="absolute right-2 text-gray-400" />
              </div>

              <div className="relative flex items-center">
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone No."
                  className="px-2 py-3 bg-transparent ring-1 ring-gray-200 dark:ring-gray-600 w-full rounded-lg text-sm text-primary-900 dark:text-primary-100 hover:ring-accent-500 focus:outline-none"
                />
                <FaPhone className="absolute right-2 text-gray-400" />
              </div>

              <div className="relative flex items-center sm:col-span-2">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write Message*"
                  className="px-2 py-3 bg-transparent ring-1 ring-gray-200 dark:ring-gray-600 w-full rounded-lg text-sm text-primary-900 dark:text-primary-100 hover:ring-accent-500 focus:outline-none"
                  required
                ></textarea>
                <FaPaperPlane className="absolute right-2 text-gray-400" />
              </div>
            </div>

            {messageError && (
              <p className="text-red-600 dark:text-red-400 mt-2">
                {messageError}
              </p>
            )}
            {error && (
              <p className="text-red-600 dark:text-red-400 mt-2">{error}</p>
            )}
            {success && (
              <p className="text-green-600 dark:text-green-400 mt-2">
                {success}
              </p>
            )}

            <button
              type="submit"
              disabled={loading || messageError}
              className={`mt-12 flex items-center justify-center text-sm lg:ml-auto max-lg:w-full rounded-lg px-4 py-3 tracking-wide text-white bg-accent-500 hover:bg-accent-600 transition duration-200 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {!loading && <FaPaperPlane className="mr-2" />}
              {loading ? "Submitting..." : "Send Message"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
