import React from "react";
import SlideSystemImage from "../../../assets/scp/SlideSystem.webp";
import { useNavigate } from "react-router-dom";

const SlideSystem = () => {
  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/support");
  };

  return (
    <div className="flex flex-col items-center p-4 pt-8 md:p-8 lg:p-12 bg-background-100 dark:bg-background-900 text-text-800 dark:text-text-100">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between my-8 pt-12 max-w-6xl w-full gap-12">
        <div className="space-y-4 self-start md:w-1/2">
          <h1 className="text-4xl font-bold text-primary-800 dark:text-primary-300">
            SlideSystem
          </h1>
          <h2 className="text-xl text-accent-500 dark:text-accent-400">
            Seamless System-Level Design and Integration
          </h2>
          <p className="text-text-600 dark:text-text-400 text-justify">
            SlideSystem provides a user-friendly solution for system-level
            design and integration, enabling users to effortlessly create and
            visualize intricate digital systems. With support for various
            modeling styles and configurable power ports, SlideSystem facilitates
            a holistic approach to digital system design and implementation.
          </p>
        </div>
        <div className="self-center">
          <img
            src={SlideSystemImage}
            alt="SlideSystem Interface"
            loading="lazy"
            className="rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
      <div className="mt-12 max-w-6xl w-full space-y-4">
        <h3 className="text-2xl font-bold text-primary-800 dark:text-primary-300">
          Features and Benefits
        </h3>
        <ul className="list-decimal list-inside space-y-2 text-justify">
          {[
            "Streamlined System-Level Design: SlideSystem simplifies the process of designing complex digital systems, offering a user-friendly interface.",
            "Visual Integration Capabilities: With SlideSystem, users can easily visualize and assemble intricate digital circuits in a modular fashion, facilitating a comprehensive approach to system design.",
            "Modeling Flexibility: SlideSystem supports various modeling styles, including Blackbox, Whitebox, and instance, providing users with the flexibility to adapt their designs to different requirements.",
            "Configurable Power Ports: SlideSystem enables the definition of optional power and ground ports during system modeling, whether through black-box, white-box, or design instance creation.",
            "Enhanced Efficiency: By providing a comprehensive suite of tools and features, SlideSystem enhances overall productivity and efficiency in the digital system design process.",
          ].map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">{index + 1}.</span>
              <div>
                <strong className="text-primary-700 dark:text-primary-300">
                  {feature.split(":")[0]}:
                </strong>{" "}
                {feature.split(":")[1]}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 flex justify-center">
        <button
          onClick={handleHelpClick}
          className="bg-accent-500 dark:bg-accent-400 text-white py-2 px-4 rounded shadow hover:bg-accent-600 dark:hover:bg-accent-500 transition duration-200"
          aria-label="Get help with SlideSystem"
        >
          Do you need any help on this product?
        </button>
      </div>
    </div>
  );
};

export default SlideSystem;
