import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { navigationData } from "../data/navigationData";
import { FiCheckCircle } from "react-icons/fi";

const GetADemo = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    telephone: "",
    state: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const { firstName, lastName, companyName, companyEmail, telephone, state } =
      formData;
    if (
      !firstName ||
      !lastName ||
      !companyName ||
      !companyEmail ||
      !telephone ||
      !state
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post(`${apiURL}/api/demo-request`, formData);

      if (res.status === 201) {
        setSuccess(res.data.message);
        setSubmitted(true);

        setFormData({
          firstName: "",
          lastName: "",
          companyName: "",
          companyEmail: "",
          telephone: "",
          state: "",
        });
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 py-24 bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-100">
      <h2 className="text-4xl font-extrabold text-center mb-2 text-primary-500 dark:text-primary-300">
        Request a SlideCircuitPro Demo
      </h2>
      {submitted ? (
        <div className="text-center">
          <FiCheckCircle className="text-green-500 dark:text-green-400 text-6xl mx-auto mb-4" />
          <p className="text-xl font-semibold text-text-900 dark:text-text-200">
            {success}
          </p>
        </div>
      ) : (
        <div className="container flex flex-col justify-center items-center py-12">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col p-8 md:px-24 rounded-md shadow-xl ring-1 ring-text-300 dark:ring-text-700 hover:ring-accent-500 dark:hover:ring-accent-400 transition duration-300"
          >
            {error && <p className="text-red-500">{error}</p>}
            <p className="mb-4">
              <span className="font-bold text-text-700 dark:text-text-300">
                Note:
              </span>{" "}
              Fields marked with an asterisk (*) are required.
            </p>

            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                placeholder="First Name*"
                className="border border-text-300 dark:border-text-700 rounded-lg p-3 focus:border-primary-500 dark:focus:border-primary-400 focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-500 text-text-900 dark:text-text-200 bg-background-50 dark:bg-background-800"
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                placeholder="Last Name*"
                className="border border-text-300 dark:border-text-700 rounded-lg p-3 focus:border-primary-500 dark:focus:border-primary-400 focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-500 text-text-900 dark:text-text-200 bg-background-50 dark:bg-background-800"
              />
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                placeholder="Company Name*"
                className="border border-text-300 dark:border-text-700 rounded-lg p-3 focus:border-primary-500 dark:focus:border-primary-400 focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-500 text-text-900 dark:text-text-200 bg-background-50 dark:bg-background-800"
              />
              <input
                type="email"
                name="companyEmail"
                value={formData.companyEmail}
                onChange={handleChange}
                placeholder="Company Email*"
                className="border border-text-300 dark:border-text-700 rounded-lg p-3 focus:border-primary-500 dark:focus:border-primary-400 focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-500 text-text-900 dark:text-text-200 bg-background-50 dark:bg-background-800"
              />
              <input
                type="tel"
                name="telephone"
                value={formData.telephone}
                onChange={handleChange}
                placeholder="Telephone*"
                className="border border-text-300 dark:border-text-700 rounded-lg p-3 focus:border-primary-500 dark:focus:border-primary-400 focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-500 text-text-900 dark:text-text-200 bg-background-50 dark:bg-background-800"
              />
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="State / Province*"
                className="border border-text-300 dark:border-text-700 rounded-lg p-3 focus:border-primary-500 dark:focus:border-primary-400 focus:ring-2 focus:ring-primary-300 dark:focus:ring-primary-500 text-text-900 dark:text-text-200 bg-background-50 dark:bg-background-800"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="mt-4 w-full bg-primary-500 dark:bg-primary-400 text-background-50 dark:text-background-900 font-bold py-3 rounded-lg hover:bg-primary-600 dark:hover:bg-primary-300 transition duration-300"
            >
              {loading ? "Submitting..." : "I'd like a demo"}
            </button>
          </form>
        </div>
      )}

      {/* Products and Resources Section */}
      <div className="flex flex-col sm:flex-row justify-evenly items-center gap-8 mt-12">
        <div className="rounded-md ring-1 ring-text-300 dark:ring-text-700 hover:ring-accent-500 dark:hover:ring-accent-400 transition p-4">
          <h3 className="text-xl font-bold text-accent-500 dark:text-accent-400 mb-4">
            Products
          </h3>
          <ul className="list-disc list-inside text-text-700 dark:text-text-300">
            {navigationData[1].submenu[0].submenu.map((item, id) => (
              <Link key={id} to={item.path}>
                <li className="hover:text-primary-500 dark:hover:text-primary-300 transition">
                  {item.title}
                </li>
              </Link>
            ))}
          </ul>
        </div>

        <div className="rounded-md ring-1 ring-text-300 dark:ring-text-700 hover:ring-accent-500 dark:hover:ring-accent-400 transition p-4">
          <h3 className="text-xl font-bold text-accent-500 dark:text-accent-400 mt-8 mb-4">
            Resources
          </h3>
          <ul className="list-disc list-inside text-text-700 dark:text-text-300">
            {navigationData[2].submenu.map((item, id) => (
              <Link key={id} to={item.path}>
                <li className="hover:text-primary-500 dark:hover:text-primary-300 transition">
                  {item.title}
                </li>
              </Link>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GetADemo;
