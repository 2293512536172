import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../redux/authSlice";

const Login = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginInput, setLoginInput] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await fetch(`${apiURL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ login: loginInput, password }),
      });

      const result = await response.json();

      if (response.ok) {
        localStorage.setItem("token", result.token);
        dispatch(login({ token: result.token, user: result.user }));
        navigate("/");
      } else {
        setError(
          result.msg ||
            result.errors[0].msg ||
            result.errors[1].msg ||
            "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      setError("Failed to connect to the server. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex px-4 sm:px-0 min-h-screen flex-col items-center justify-center bg-background-100 dark:bg-background-900">
      <div className="mx-4 w-full max-w-md rounded-lg bg-white dark:bg-background-800 p-8 shadow-xl sm:mx-0">
        <div className="mb-8 flex flex-col items-center justify-center gap-4">
          <img src="logo.png" width={200} height={200} alt="logo" />
          <h2 className="text-2xl font-bold text-primary-500 dark:text-primary-400">
            Login
          </h2>
        </div>
        <form className="space-y-4" onSubmit={handleLogin}>
          <div>
            <label
              htmlFor="login"
              className="block text-sm font-medium text-text-900 dark:text-text-200"
            >
              Username or Email
            </label>
            <input
              id="login"
              type="text"
              placeholder=""
              value={loginInput}
              onChange={(e) => setLoginInput(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
            />
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                Password
              </label>
              <Link
                to="/forgot-password"
                className="text-sm font-medium text-accent-500 dark:text-accent-400 underline hover:text-secondary-500 dark:hover:text-secondary-400"
              >
                Forgot Password?
              </Link>
            </div>
            <input
              id="password"
              type="password"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-br from-primary-500 to-secondary-500 text-white text-md font-semibold py-2 rounded-md shadow-sm hover:text-text-900 dark:hover:text-text-200"
            disabled={loading}
          >
            {loading ? "Signing In..." : "Sign In"}
          </button>
        </form>
        {error && (
          <p className="mt-4 text-center text-sm text-accent-500 dark:text-accent-400">
            {error}
          </p>
        )}
        <div className="mt-4 text-center text-sm text-text-700 dark:text-text-300">
          Don&apos;t have an account?{" "}
          <Link
            to="/signup"
            className="font-medium text-accent-500 dark:text-accent-400 hover:underline"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
