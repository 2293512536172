// src/redux/authActions.js
import { updateUser, logout, setError } from "./authSlice";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("token");
  dispatch(logout());
};

export const updateUserDetails = (userData) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${apiUrl}/api/user/update-user`,
      userData
    );
    dispatch(updateUser(data.user));
  } catch (error) {
    dispatch(
      setError(error.response?.data?.message || "Failed to update user details")
    );
  }
};

export const changePassword = (passwords) => async (dispatch) => {
  try {
    await axios.post(`${apiUrl}/api/user/change-password`, passwords);
  } catch (error) {
    dispatch(
      setError(error.response?.data?.message || "Failed to change password")
    );
  }
};

export const deleteUserAccount = () => async (dispatch) => {
  try {
    await axios.delete(`${apiUrl}/api/user/delete-account`);
    dispatch(logout());
  } catch (error) {
    dispatch(
      setError(error.response?.data?.message || "Failed to delete account")
    );
  }
};
