import React from "react";
import SlideWaveImage from "../../../assets/scp/SlideWave.webp"; // Replace with the actual path to your image
import { useNavigate } from "react-router-dom";

const SlideWave = () => {
  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/support");
  };

  return (
    <div className="flex flex-col items-center p-4 pt-8 md:p-8 lg:p-12 bg-background-100 dark:bg-background-900 text-text-800 dark:text-text-100">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between my-8 pt-12 max-w-6xl w-full gap-12">
        <div className="space-y-4 self-start md:w-1/2">
          <h1 className="text-4xl font-bold text-primary-800 dark:text-primary-300">
            SlideWave
          </h1>
          <h2 className="text-xl text-accent-500 dark:text-accent-400">
            Power-Packed Waveform Creation and Visualization
          </h2>
          <p className="text-text-600 dark:text-text-400 text-justify">
            Creates timing waveforms with ease, allowing users to visualize and
            analyze digital signals alongside their circuit designs.
          </p>
        </div>
        <div className="self-center">
          <img
            src={SlideWaveImage}
            alt="SlideWave Interface"
            loading="lazy"
            className="rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
      <div className="mt-12 max-w-6xl w-full space-y-4">
        <h3 className="text-2xl font-bold text-primary-800 dark:text-primary-300">
          Features and Benefits
        </h3>
        <ul className="list-decimal list-inside space-y-2 text-justify">
          {[
            "Extensive Timing Editor: Streamline the waveform creation process with a comprehensive drawing editor and specialized components, enabling efficient drag-and-drop usage.",
            "Time-Saving Clock Generation: Quickly define clock parameters with a single click, reducing the need for manual line creation and adjustment, saving valuable time during the design process.",
            "Precision and Accuracy: Ensure precision in signal creation through grid line management, allowing for accurate and error-free waveform generation.",
            "Enhanced Customization: Enjoy a wide range of customization options, including signal colors, font sizes, and text integration, empowering users to create visually engaging and customized waveforms tailored to specific project requirements.",
          ].map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">{index + 1}.</span>
              <div>
                <strong className="text-primary-700 dark:text-primary-300">
                  {feature.split(":")[0]}:
                </strong>{" "}
                {feature.split(":")[1]}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 flex justify-center">
        <button
          onClick={handleHelpClick}
          className="bg-accent-500 dark:bg-accent-400 text-white py-2 px-4 rounded shadow hover:bg-accent-600 dark:hover:bg-accent-500 transition duration-200"
          aria-label="Get help with SlideWave"
        >
          Do you need any help with this product?
        </button>
      </div>
    </div>
  );
};

export default SlideWave;
