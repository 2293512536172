import React from 'react'
import UnderDevelopment from '../../components/UnderDevelopment'

const Webinars = () => {
  return (
    <div className='pt-18'>
      <UnderDevelopment/>
    </div>
  )
}

export default Webinars