import React from "react";
import SlideRTLImage from "../../../assets/scp/SlideRTL.webp";
import { useNavigate } from "react-router-dom";

const SlideRTL = () => {
  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/support");
  };

  return (
    <div className="flex flex-col items-center pt-8 p-4 md:p-8 lg:p-12 bg-background-100 dark:bg-background-900 text-text-800 dark:text-text-100">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between my-8 pt-12 max-w-6xl w-full gap-12">
        <div className="space-y-4 self-start md:w-1/2">
          <h1 className="text-4xl font-bold text-primary-800 dark:text-primary-300">
            SlideRTL
          </h1>
          <h2 className="text-xl text-accent-500 dark:text-accent-400">
            Effortless RTL Code Generation
          </h2>
          <p className="text-text-600 dark:text-text-400 text-justify">
            SlideRTL seamlessly integrates RTL code generation, eliminating
            manual coding. It supports various hardware description language
            formats, including Verilog, VHDL, and System Verilog, for both
            circuit and system-level code generation. The effortless export
            function enables seamless integration into RTL-based digital design
            workflows, saving time and minimizing errors.
          </p>
        </div>
        <div className="self-center">
          <img
            src={SlideRTLImage}
            alt="SlideRTL Interface"
            loading="lazy"
            className="rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
      <div className="mt-12 max-w-6xl w-full space-y-4">
        <h3 className="text-2xl font-bold text-primary-800 dark:text-primary-300">
          Features and Benefits
        </h3>
        <ul className="list-decimal list-inside space-y-2 text-justify">
          {[
            "Seamless Integration: SlideRTL seamlessly integrates RTL code generation into the design process, eliminating the need for manual coding and streamlining the overall workflow.",
            "Versatile HDL Support: It offers support for a wide range of hardware description language formats such as Verilog, VHDL, and System Verilog, catering to diverse design requirements.",
            "Enhanced Flexibility: SlideRTL provides a flexible HDL modeling style, supporting both behavioral and structural approaches for RTL design, allowing for more versatile design implementations.",
            "Circuit-Level and System-Level Code: RTL code generation options at both circuit and system levels.",
            "Efficient Export Functionality: The tool enables the effortless export of generated RTL code, facilitating smooth integration into various RTL-based digital design workflows, thereby reducing errors and improving overall efficiency.",
            "Increased Productivity: By providing a comprehensive solution for RTL code generation, SlideRTL enhances the productivity of designers, enabling them to focus on higher-level design aspects rather than spending time on manual coding tasks.",
          ].map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">{index + 1}.</span>
              <div>
                <strong className="text-primary-700 dark:text-primary-300">
                  {feature.split(":")[0]}:
                </strong>{" "}
                {feature.split(":")[1]}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 flex justify-center">
        <button
          onClick={handleHelpClick}
          className="bg-accent-500 dark:bg-accent-400 text-white py-2 px-4 rounded shadow hover:bg-accent-600 dark:hover:bg-accent-500 transition duration-200"
          aria-label="Get help with SlideRTL"
        >
          Do you need any help on this product?
        </button>
      </div>
    </div>
  );
};

export default SlideRTL;
