import React, { useState, useEffect } from "react";
import axios from "axios";

const AddressesContent = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const [billingAddress, setBillingAddress] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [shippingAddress, setShippingAddress] = useState({
    street: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [isEditingBilling, setIsEditingBilling] = useState(false);
  const [isEditingShipping, setIsEditingShipping] = useState(false);

  const [loadingBilling, setLoadingBilling] = useState(false); // Loading state for billing
  const [loadingShipping, setLoadingShipping] = useState(false); // Loading state for shipping

  const token = localStorage.getItem("token"); // Retrieve the token
  useEffect(() => {
    // Fetch the user's addresses from the database
    axios
      .get(`${apiURL}/api/user/addresses`, {
        headers: {
          "x-auth-token": token, // Set the token in the request headers
        },
      })
      .then((response) => {
        const { billingAddress, shippingAddress } = response.data;
        setBillingAddress(billingAddress || {});
        setShippingAddress(shippingAddress || {}); // Use an empty object if shipping address is not provided
      });
  }, [apiURL, token]);

  const handleBillingChange = (e) => {
    setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });
  };

  const handleShippingChange = (e) => {
    setShippingAddress({ ...shippingAddress, [e.target.name]: e.target.value });
  };

  const saveBillingAddress = () => {
    setLoadingBilling(true); // Start loading
    axios
      .post(`${apiURL}/api/user/addresses/billing`, billingAddress, {
        headers: {
          "x-auth-token": token, // Set the token in the request headers
        },
      })
      .then(() => {
        setIsEditingBilling(false);
      })
      .catch((error) => console.error("Error saving billing address:", error))
      .finally(() => {
        setLoadingBilling(false); // Stop loading
      });
  };

  const saveShippingAddress = () => {
    setLoadingShipping(true); // Start loading
    axios
      .post(`${apiURL}/api/user/addresses/shipping`, shippingAddress, {
        headers: {
          "x-auth-token": token, // Set the token in the request headers
        },
      })
      .then(() => {
        setIsEditingShipping(false);
      })
      .catch((error) => console.error("Error saving shipping address:", error))
      .finally(() => {
        setLoadingShipping(false); // Stop loading
      });
  };

  const renderAddress = (address, isEditing, handleChange, saveAddress, setIsEditing, loading, addressType) => {
    return (
      <div className="p-4 border rounded bg-gray-50">
        <h3 className="text-xl font-semibold mb-2">{addressType} Address</h3>
        {isEditing ? (
          <>
            <input
              type="text"
              name="street"
              value={address.street}
              onChange={handleChange}
              placeholder="Street"
              className="block w-full mt-2 mb-2 p-2 border rounded"
            />
            <input
              type="text"
              name="city"
              value={address.city}
              onChange={handleChange}
              placeholder="City"
              className="block w-full mt-2 mb-2 p-2 border rounded"
            />
            <input
              type="text"
              name="state"
              value={address.state}
              onChange={handleChange}
              placeholder="State"
              className="block w-full mt-2 mb-2 p-2 border rounded"
            />
            <input
              type="text"
              name="postalCode"
              value={address.postalCode}
              onChange={handleChange}
              placeholder="Postal Code"
              className="block w-full mt-2 mb-2 p-2 border rounded"
            />
            <input
              type="text"
              name="country"
              value={address.country}
              onChange={handleChange}
              placeholder="Country"
              className="block w-full mt-2 mb-2 p-2 border rounded"
            />
            <button
              className="mt-2 text-green-500 hover:underline"
              onClick={saveAddress}
              disabled={loading} // Disable button while loading
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              className="mt-2 text-red-500 hover:underline ml-4"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            {address.street || address.city || address.state || address.postalCode || address.country ? (
              <>
                <p className="text-gray-600">{address.street},</p>
                <p className="text-gray-600">{address.city},</p>
                <p className="text-gray-600">
                  {address.state} - {address.postalCode},
                </p>
                <p className="text-gray-600">{address.country}</p>
              </>
            ) : (
              <p className="text-gray-500 italic">No {addressType.toLowerCase()} address provided.</p>
            )}
            <button
              className="mt-2 text-blue-500 hover:underline"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Your Addresses</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Billing Address */}
        {renderAddress(billingAddress, isEditingBilling, handleBillingChange, saveBillingAddress, setIsEditingBilling, loadingBilling, "Billing")}
        {/* Shipping Address */}
        {renderAddress(shippingAddress, isEditingShipping, handleShippingChange, saveShippingAddress, setIsEditingShipping, loadingShipping, "Shipping")}
      </div>
    </div>
  );
};

export default AddressesContent;