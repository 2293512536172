import React from "react";
import { Link } from "react-router-dom";
import SocialMedia from "./SocialMedia";
import { footerLinks, companyInfo, bottomLinks } from "../data/footerData";
import ContactCard from "./ContactCard";

const Footer = () => {
  return (
    <>
      <footer className="bg-background-50 dark:bg-background-950 text-text-800 dark:text-text-100 font-heading relative z-0 border-t border-background-200 dark:border-background-700 pb-8 backdrop-blur-lg py-8 px-4 sm:px-8 md:px-16 tracking-wide">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 gap-8">
          <div className="col-span-1 lg:col-span-4 p-4 gap-8 border border-background-300 dark:border-background-800 rounded-lg flex flex-col items-center text-center bg-background-100 dark:bg-background-900">
            <div>
              <img
                src="/logo.png"
                width={200}
                alt="logo"
                className="aspect-auto"
              />
            </div>
            <div className="text-left">
              <p className="mb-2 text-[15px] text-text-600 dark:text-text-300">
                {companyInfo.description}
              </p>
            </div>
            <div className="items-center justify-center">
              <h4 className="font-semibold text-lg mb-6 text-primary-500 dark:text-primary-200">
                Follow Us
              </h4>
              <SocialMedia />
            </div>
          </div>

          {footerLinks.map((item, idx) => (
            <div
              key={idx}
              className="col-span-1 lg:col-span-2 flex flex-col items-center text-center"
            >
              <h4 className="font-semibold text-lg mb-6 text-secondary-500 dark:text-secondary-200">
                {item.section}
              </h4>
              <ul className="space-y-4">
                {item.links.map((data, idx) => (
                  <li key={idx}>
                    <Link
                      to={data.url}
                      className="hover:text-accent-500 dark:hover:text-accent-300 text-text-500 dark:text-text-400 text-[16px] transition-colors duration-300"
                    >
                      {data.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="col-span-1 lg:col-span-4 flex flex-col items-start text-start">
            <div className="items-start pb-8">
              <h4 className="font-semibold text-lg mb-6 text-primary-500 dark:text-primary-200">
                Contact Us
              </h4>
              <p className="text-text-600 dark:text-text-300">
                Know where to find us? Let's take a look and get in touch!
              </p>
            </div>
            <div className="flex flex-col gap-4 items-start">
              <ContactCard />
            </div>
          </div>
        </div>

        <hr className="my-8 border-background-400 dark:border-background-700" />

        <div className="flex flex-col md:flex-row items-center justify-between text-center">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <p className="text-text-700 dark:text-text-300 text-sm leading-5 font-semibold">
              {companyInfo.copyright}
            </p>
          </div>
          <div className="text-center text-[1px]  opacity-0 text-text-600 dark:text-text-400">
            <span className="font-medium">About this website:</span> built and
            designed with 💚 by{" "}
            <span>
              <Link
                to="https://www.vikashpatel.tech/"
                target="_blank"
                className="italic font-medium hover:text-accent-600 dark:hover:text-accent-400 transition-colors duration-300"
              >
                Vikash Patel
              </Link>
            </span>
          </div>
          <ul className="flex gap-4 mt-4 md:mt-0 justify-center">
            {bottomLinks.map((item, idx) => (
              <li className="text-sm" key={idx}>
                <Link
                  to={item.url}
                  className="text-text-600 dark:text-text-400 hover:text-text-800 dark:hover:text-text-200 font-semibold hover:underline transition-colors duration-300"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
