import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";

const Newsletter = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const user = useSelector((state) => state.auth.user);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = user?.id;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      const response = await axios.post(`${apiURL}/api/subscribe`, {
        email,
        userId,
      });
      if (response.status === 201) {
        setMessage("You have successfully subscribed to our newsletter!");
        setIsSuccess(true);
      }
    } catch (err) {
      setMessage(
        err.response?.data?.message || "Something went wrong. Please try again."
      );
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="py-24 px-4 bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <div className="max-w-4xl w-full mx-auto text-center">
        <h2 className="text-4xl font-extrabold mb-6 leading-[45px] text-primary-700 dark:text-primary-500">
          Subscribe to Our Newsletter
        </h2>
        <p className="text-base font-semibold text-text-700 dark:text-text-300">
          Stay updated with our latest news and exclusive offers. Join our
          community today!
        </p>
        <form
          onSubmit={handleSubmit}
          className="mt-10 bg-background-200 dark:bg-background-800 flex items-center  px-2 max-w-xl mx-auto rounded-2xl border border-background-300 dark:border-background-600"
        >
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email"
            className="w-full bg-transparent  py-4 px-2 text-text-800 dark:text-text-200 text-base border-none outline-none"
            disabled={loading}
          />
          <button
            type="submit"
            className="bg-primary-600 dark:bg-primary-500 hover:bg-primary-700 dark:hover:bg-primary-400 text-white text-base font-semibold px-4 py-2 sm:px-6 sm:py-2 rounded-2xl focus:outline-none flex items-center justify-center"
            disabled={loading}
          >
            {loading ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              "Subscribe"
            )}
          </button>
        </form>
        {message && (
          <p
            className={`mt-4 text-base font-semibold ${
              isSuccess
                ? "text-green-600 dark:text-green-400"
                : "text-red-600 dark:text-red-400"
            }`}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
