export const navigationData = [
  { title: "Home", path: "/" },
  {
    title: "Products",
    path: "/products",
    dropdownIcon: true,
    submenu: [
      {
        title: "SlideCircuitPro",
        path: "/products",
        dropdownIcon: true,
        submenu: [
          {
            title: "SlideCircuit",
            path: "/products/slidecircuitpro/slidecircuit",
          },
          { title: "SlideWave", path: "/products/slidecircuitpro/slidewave" },
          {
            title: "SlideSystem",
            path: "/products/slidecircuitpro/slidesystem",
          },
          { title: "SlideRTL", path: "/products/slidecircuitpro/slidertl" },
          { title: "SlideUPF", path: "/products/slidecircuitpro/slideupf" },
        ],
      },
      { title: "EDAcloud", path: "/products/eda-cloud" },
    ],
  },
  {
    title: "Resources",
    path: "#",
    dropdownIcon: true,
    submenu: [
      { title: "White Papers", path: "/resources/white-papers" },
      { title: "Videos", path: "/resources/videos" },
      { title: "Webinars", path: "/resources/webinars" },
      { title: "Downloads", path: "/resources/downloads" },
    ],
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    dropdownIcon: true,
    submenu: [
      { title: "Get a Demo", path: "/get-a-demo" },
      { title: "Request Evaluation", path: "/request-evaluation" },
      { title: "Support", path: "/support" },
    ],
  },
  {
    title: "Company",
    path: "/company",
    dropdownIcon: true,
    submenu: [
      { title: "About Us", path: "/company/about-us" },
      { title: "Careers", path: "/company/careers" },
      { title: "Partners", path: "/company/partners" },
      { title: "Media", path: "/company/media" },
      { title: "Newsletter", path: "/company/newsletter" },
      { title: "FAQs", path: "/company/faqs" },
    ],
  },
  // { title: "License", path: "/license" },
];
