import React, { useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowDropdown } from "react-icons/io";

const Collapsible = ({ features }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button
        className="flex items-center justify-between w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className="text-lg transition-transform duration-300 hover:text-indigo-600 hover:scale-110 font-medium">
          Features
        </h4>
        <IoIosArrowDropdown
          className={`transition-transform duration-300 hover:text-indigo-600 hover:scale-150 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div className="space-y-2 pt-2">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center gap-2">
              <FaCheck className="h-4 w-4 text-primary" />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Collapsible;
