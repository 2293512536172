import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isAllowedEmail } from "../../utils/emailValidation";
import { countriesData } from "../../data/countriesData";

const Signup = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    phone: "",
    gender: "",
    userType: "",
    country: "",
    password: "",
    confirmPassword: "",
    acceptTnC: false,
    sendCredentials: false,
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password, confirmPassword, userType, acceptTnC } = formData;

    if (!isAllowedEmail(email)) {
      setError("Please use a work or educational email address.");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!userType) {
      setError("Please select a user type.");
      return;
    }
    if (!acceptTnC) {
      setError("You must accept the terms and conditions.");
      return;
    }

    setLoading(true);
    setError("");
    setMessage("");

    try {
      console.log({ formData });
      const response = await fetch(`${apiURL}/api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage(
          "Verification email sent. Please check your inbox, remember to check your spam folder also."
        );
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      } else {
        setError(
          result.msg ||
            result.errors[0].msg ||
            result.errors[1].msg ||
            result.errors[2].msg ||
            result.errors[3].msg ||
            result.errors[4].msg ||
            "Something went wrong. Please try again."
        );
      }
    } catch (error) {
      setError("Failed to connect to the server. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex pt-24 px-4 sm:px-0 min-h-screen flex-col items-center justify-center bg-background-100 dark:bg-background-900">
      <div className="mx-4 m-12 w-full max-w-md rounded-lg bg-white dark:bg-background-800 p-8 shadow-xl sm:mx-0">
        <div className="mb-4 flex items-center justify-center">
          <img src="logo.png" width={200} height={200} alt="logo" />
        </div>
        <div className="mb-8 flex items-center justify-center">
          <h2 className="text-2xl text-primary-500 dark:text-primary-400 font-bold">
            Signup
          </h2>
        </div>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-x-0 space-y-4 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                First Name*
              </label>
              <input
                id="firstName"
                type="text"
                required
                placeholder=""
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
                />
            </div>
            <div className="w-full">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                placeholder=""
                value={formData.lastName}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
                />
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-text-900 dark:text-text-200"
            >
              Username*
            </label>
            <input
              id="username"
              type="text"
              name="username"
              placeholder="abcd1234"
              value={formData.username}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-text-900 dark:text-text-200"
            >
              E-mail*
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
              placeholder="name@example.com"
            />
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-4 space-x-0 space-y-4 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                Phone <span class="text-sm text-gray-400">(optional)</span>
              </label>
              <input
                id="phone"
                type="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
                placeholder="+91 99XX XXXX XX"
              />
            </div>
            <div className="w-full">
              <label
                htmlFor="gender"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                Gender*
              </label>
              <select
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
                >
                <option value="" disabled>
                  Select your gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row sm:space-x-4 space-x-0 space-y-4 sm:space-y-0">
            <div className="w-full">
              <label
                htmlFor="userType"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                User Type*
              </label>
              <select
                id="userType"
                name="userType"
                value={formData.userType}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
                >
                <option value="" disabled>
                  Select user type
                </option>
                <option value="Student">Student</option>
                <option value="Professional">Professional</option>
              </select>
            </div>
            <div className="w-full">
              <label
                htmlFor="country"
                className="block text-sm font-medium text-text-900 dark:text-text-200"
              >
                Country*
              </label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
                >
                <option value="" disabled>
                  Select your country
                </option>
                {countriesData.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-text-900 dark:text-text-200"
            >
              Password*
            </label>
            <input
              id="password"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
              placeholder="••••••••"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-text-900 dark:text-text-200"
            >
              Confirm Password*
            </label>
            <input
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-100 dark:bg-background-700 px-3 py-2 text-sm shadow-sm focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400 text-text-900 dark:text-text-200"
              placeholder="••••••••"
            />
          </div>

          <div className="flex items-center mt-6">
            <input
              id="acceptTnC"
              name="acceptTnC"
              type="checkbox"
              checked={formData.acceptTnC}
              onChange={handleChange}
              className="h-4 w-4 rounded border-gray-300 text-primary shadow-sm "
            />
            <label
              htmlFor="acceptTnC"
              className="ml-3 block text-sm text-gray-800 text-text-900 dark:text-text-200"
            >
              I accept the{" "}
              <Link
                to={"/terms-and-conditions"}
                target="_blank"
                className="text-blue-500 font-semibold hover:underline ml-1"
              >
                Terms and Conditions
              </Link>
            </label>
          </div>

          <div className="flex items-center mt-6">
            <input
              id="sendCredentials"
              name="sendCredentials"
              type="checkbox"
              checked={formData.sendCredentials}
              onChange={handleChange}
              className="h-4 w-4 rounded border-gray-300 text-primary shadow-sm  "
            />
            <label
              htmlFor="sendCredentials"
              className="ml-3 block text-sm text-gray-800 text-text-900 dark:text-text-200"
            >
              Send these credentials via email
            </label>
          </div>

          <button
            type="submit"
            className="w-full bg-gradient-to-br from-primary-500 to-secondary-500 text-white text-md font-semibold py-2 rounded-md shadow-sm hover:text-text-900 dark:hover:text-text-200"
            disabled={loading}
          >
            {loading ? "Registering..." : "Register"}
          </button>
        </form>
        {error && (
          <p className="mt-4 text-center text-sm text-red-600">{error}</p>
        )}
        {message && (
          <p className="mt-4 text-center text-sm text-green-600">{message}</p>
        )}
        <div className="mt-4 text-center text-sm text-gray-600">
          Already have an account?{" "}
          <Link
            to={"/login"}
            className="font-medium text-accent-500 dark:text-accent-400 hover:underline"
          >
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
