import React from "react";
import { QnA } from "../../data/faqData";

export default function FAQ() {
  return (
    <div className="pt-24 p-8 mx-auto px-4 sm:px-6 lg:px-8 bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <div className="p-6 text-center">
        <h2 className="text-4xl font-extrabold text-primary-700 dark:text-primary-500">
          Frequently Asked Questions
        </h2>
        <p className="text-text-700 dark:text-text-300 mt-4 text-2xl font-semibold">
          Explore our comprehensive FAQ to find answers to common queries.
        </p>
      </div>

      <div className="rounded-lg shadow-lg bg-background-200 dark:bg-background-800">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-6">
          {QnA.map((faq, index) => (
            <div
              key={index}
              className="bg-background-300 dark:bg-background-700 p-6 rounded-lg border border-background-400 dark:border-background-600"
            >
              <h3 className="text-lg font-semibold text-primary-700 dark:text-primary-500 mb-2">
                {faq.question}
              </h3>
              <p className="text-text-700 dark:text-text-300 text-md font-medium">
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
