import React, { useState } from "react";
import person from "../assets/person.png";
import axios from "axios";
import { FiCheckCircle } from "react-icons/fi";

const RequestEvaluation = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    companyEmail: "",
    telephone: "",
    state: "",
    products: "",
    additionalInfo: "",
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const { firstName, lastName, companyName, companyEmail, telephone, state } =
      formData;
    if (
      !firstName ||
      !lastName ||
      !companyName ||
      !companyEmail ||
      !telephone ||
      !state
    ) {
      setError("Please fill in all required fields.");
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post(
        `${apiURL}/api/evaluation-request`,
        formData
      );
      if (res.status === 201) {
        setSuccess(res.data.message);

        setSubmitted(true);

        // Clear form
        setFormData({
          firstName: "",
          lastName: "",
          companyName: "",
          companyEmail: "",
          telephone: "",
          state: "",
          products: "",
          additionalInfo: "",
        });
      }
    } catch (err) {
      console.error(err);
      setError("Failed to submit the evaluation request.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200 mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <h2 className="text-4xl font-extrabold text-center mb-8 dark:text-primary-300">
          Request a SlideCircuitPro Evaluation
        </h2>
        {submitted ? (
          <div className="text-center">
            <FiCheckCircle className="text-green-500 dark:text-green-400 text-6xl mx-auto mb-4" />
            <p className="text-xl font-semibold text-text-900 dark:text-text-200">
              {success}
            </p>
          </div>
        ) : (
          <div className="container mx-auto flex flex-col bg-background-100 dark:bg-background-900 md:flex-row items-center justify-center">
            <form
              className="md:w-1/2 w-full text-center md:text-left px-4 md:px-8 lg:px-16 xl:px-24 shadow-xl py-4 rounded-lg bg-background-200 dark:bg-background-700"
              onSubmit={handleSubmit}
            >
              {error && <p className="text-red-500">{error}</p>}
              <p className="mb-4">
                <span className="font-bold text-text-700 dark:text-text-300">
                  Note:
                </span>{" "}
                Fields marked with an asterisk (*) are required.
              </p>

              <div className="grid grid-cols-2 gap-4">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name*"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name*"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company Name*"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="email"
                  name="companyEmail"
                  value={formData.companyEmail}
                  onChange={handleChange}
                  placeholder="Company Email*"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="tel"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleChange}
                  placeholder="Telephone*"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="State / Province*"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="text"
                  name="products"
                  value={formData.products}
                  onChange={handleChange}
                  placeholder="Which Product(s)?"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
                <input
                  type="text"
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                  placeholder="Anything you'd like to add?"
                  className="border border-text-300 dark:border-text-700 rounded-lg p-3 bg-background-50 dark:bg-background-800 text-text-900 dark:text-text-200"
                />
              </div>
              <button
                type="submit"
                disabled={loading}
                className="mt-4 w-full bg-primary-500 dark:bg-primary-400 text-background-50 dark:text-background-900 font-bold py-3 rounded-lg hover:bg-primary-600 dark:hover:bg-primary-300 transition duration-300"
              >
                {loading ? "Submitting..." : "Request Evaluation"}
              </button>
            </form>

            <div className="md:w-1/2 w-full mt-8 md:mt-0 flex justify-center">
              <img
                src={person}
                alt="Person holding a laptop"
                className="max-w-full h-auto"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RequestEvaluation;
