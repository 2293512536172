export const QnA = [
  {
    question: "What services does SlideCircuitPro offer?",
    answer:
      "SlideCircuitPro provides advanced circuit design and simulation tools for engineers and hobbyists.",
  },
  {
    question: "What is EDA cloud services?",
    answer:
      "EDA cloud services provide electronic design automation tools hosted in the cloud, enabling efficient design and collaboration.",
  },
  {
    question: "How can I contact customer support?",
    answer:
      "You can reach our customer support team through the 'Contact Us' page or by emailing support@compcarta.com.",
  },
  {
    question: "Are there any tutorials available?",
    answer:
      "Yes, we offer a variety of tutorials and documentation to help you get started. Visit our 'Resources' section for more information.",
  },
  {
    question: "Can I upgrade my plan later?",
    answer:
      "Absolutely! You can upgrade your plan at any time from your account settings or by contacting our support team.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "We accept various payment methods including credit/debit cards, PayPal, and bank transfers.",
  },
  {
    question: "How secure is my data?",
    answer:
      "We prioritize your data security with advanced encryption and regular security audits to ensure your information is safe.",
  },
  {
    question: "Can I collaborate with my team on SlideCircuitPro?",
    answer:
      "Yes, SlideCircuitPro allows team collaboration with real-time updates and version control.",
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, we offer a 14-day free trial for new users. Sign up to explore our features without any commitment.",
  },
  {
    question: "What happens after my free trial ends?",
    answer:
      "After your free trial ends, you can choose a subscription plan that suits your needs. Your data will be retained even if you decide not to subscribe immediately.",
  },
  {
    question: "How often are new features added to SlideCircuitPro?",
    answer:
      "We continuously update SlideCircuitPro with new features and improvements based on user feedback.",
  },
  {
    question: "How can I reset my password?",
    answer:
      "To reset your password, go to the login page and click on the 'Forgot Password' link. Follow the instructions sent to your email.",
  },
  {
    question: "Can I import my existing designs into SlideCircuitPro?",
    answer:
      "Yes, you can easily import your existing designs in various formats to continue working on them within SlideCircuitPro.",
  },
  {
    question: "Do you offer customer support during the free trial?",
    answer:
      "Absolutely! Our customer support is available to help you throughout your free trial period.",
  },
  {
    question: "Do you offer educational discounts?",
    answer:
      "Yes, we provide discounts for educational institutions and students. Contact us for more details.",
  },
  {
    question: "What kind of EDA tools are included in the cloud services?",
    answer:
      "Our EDA cloud services include tools for circuit design, simulation, PCB layout, and more.",
  },
  {
    question: "Is my work automatically saved?",
    answer:
      "Yes, SlideCircuitPro automatically saves your work to ensure you don't lose any progress.",
  },
  {
    question: "Can I use SlideCircuitPro offline?",
    answer:
      "SlideCircuitPro operates both in offline and online mode, but certain features are not available online.",
  },
];
