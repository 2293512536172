import React from "react";
import { Link } from "react-router-dom";
import { contactDetails } from "../data/footerData";

const ContactCard = () => {
  return (
    <>
      {contactDetails.map((item, idx) => {
        return (
          <div
            key={idx}
            className="flex items-center justify-center hover:text-primary-600 dark:hover:text-primary-300 transition-colors duration-300"
          >
            <div className="bg-background-200 dark:bg-background-800 border border-background-300 dark:border-background-700 h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              {item.icon}
            </div>
            <Link
              to={item.to}
              className="text-text-600 dark:text-text-300 hover:text-accent-500 dark:hover:text-accent-400 text-sm ml-3 transition-colors duration-300"
            >
              <p className="font-semibold">{item.data}</p>
            </Link>
          </div>
        );
      })}
    </>
  );
};

export default ContactCard;
