import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateUserDetails, changePassword, deleteUserAccount } from "../../redux/authActions";
import Spinner from "../../components/Spinner"; // A custom spinner component
import { toast } from "react-toastify"; // For displaying success and error messages

const AccountSettings = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [bio, setBio] = useState(user.bio || "");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailNotifications, setEmailNotifications] = useState(user.emailNotifications || false);
  const [pushNotifications, setPushNotifications] = useState(user.pushNotifications || false);
  const [twoFactorAuth, setTwoFactorAuth] = useState(user.twoFactorAuth || false);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Set initial state values from user data
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setEmail(user.email);
    setBio(user.bio || "");
    setEmailNotifications(user.emailNotifications || false);
    setPushNotifications(user.pushNotifications || false);
    setTwoFactorAuth(user.twoFactorAuth || false);
  }, [user]);

  const validateForm = () => {
    const errors = {};
    if (!firstName) errors.firstName = "First name is required";
    if (!lastName) errors.lastName = "Last name is required";
    if (!email) errors.email = "Email is required";
    if (!/^\S+@\S+\.\S+$/.test(email)) errors.email = "Email is not valid";
    if (newPassword && newPassword !== confirmPassword) {
      errors.newPassword = "New password and confirm password do not match";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveChanges = async () => {
    if (!validateForm()) return;

    const updatedUser = {
      firstName,
      lastName,
      email,
      bio,
      emailNotifications,
      pushNotifications,
      twoFactorAuth,
    };
    setLoading(true);
    try {
      await dispatch(updateUserDetails(updatedUser));
      toast.success("Account details updated successfully!");
    } catch (error) {
      toast.error("Failed to update account details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (!currentPassword) {
      setErrors({ currentPassword: "Current password is required" });
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrors({ confirmPassword: "New password and confirm password do not match" });
      return;
    }
    setLoading(true);
    try {
      await dispatch(changePassword(newPassword));
      toast.success("Password changed successfully!");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error("Failed to change password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
      setLoading(true);
      try {
        await dispatch(deleteUserAccount());
        toast.success("Account deleted successfully.");
      } catch (error) {
        toast.error("Failed to delete account. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="flex-1 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold mb-4">Account Details</h1>
      <div className="grid gap-8">
        <section>
          <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
          <div className="grid gap-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  id="firstName"
                  className={`mt-1 block w-full px-3 py-2 border ${errors.firstName ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>}
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  id="lastName"
                  className={`mt-1 block w-full px-3 py-2 border ${errors.lastName ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>}
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                id="email"
                type="email"
                className={`mt-1 block w-full px-3 py-2 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            <div>
              <label htmlFor="bio" className="block text-sm font-medium text-gray-700">
                Bio
              </label>
              <textarea
                id="bio"
                rows={3}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>
            <button
              className="bg-indigo-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700 justify-self-end"
              onClick={handleSaveChanges}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Save Changes"}
            </button>
          </div>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Change Password</h2>
          <div className="grid gap-4">
            <div>
              <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                Current Password
              </label>
              <input
                id="currentPassword"
                type="password"
                className={`mt-1 block w-full px-3 py-2 border ${errors.currentPassword ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              {errors.currentPassword && <p className="text-red-500 text-sm mt-1">{errors.currentPassword}</p>}
            </div>
            <div>
              <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <input
                id="newPassword"
                type="password"
                className={`mt-1 block w-full px-3 py-2 border ${errors.newPassword ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {errors.newPassword && <p className="text-red-500 text-sm mt-1">{errors.newPassword}</p>}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                className={`mt-1 block w-full px-3 py-2 border ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {errors.confirmPassword && <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>}
            </div>
            <button
              className="bg-green-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-green-700 justify-self-end"
              onClick={handleChangePassword}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Change Password"}
            </button>
          </div>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4">Notifications & Security</h2>
          <div className="grid gap-4">
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">Email Notifications</label>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-600"
                checked={emailNotifications}
                onChange={(e) => setEmailNotifications(e.target.checked)}
              />
            </div>
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">Push Notifications</label>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-600"
                checked={pushNotifications}
                onChange={(e) => setPushNotifications(e.target.checked)}
              />
            </div>
            <div className="flex items-center justify-between">
              <label className="text-sm font-medium text-gray-700">Two-Factor Authentication</label>
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-indigo-600"
                checked={twoFactorAuth}
                onChange={(e) => setTwoFactorAuth(e.target.checked)}
              />
            </div>
          </div>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-4 text-red-600">Delete Account</h2>
          <div className="grid gap-4">
            <p className="text-sm text-gray-700">
              Once you delete your account, there is no going back. Please be certain.
            </p>
            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-red-700 justify-self-end"
              onClick={handleDeleteAccount}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Delete Account"}
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AccountSettings;
