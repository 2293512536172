import React, { useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { features } from "../../data/features";
import Collapsible from "../../components/Collapsible";
import chip from '../../assets/chip2.jpeg'

const Evaluation = () => {
  const navigate = useNavigate();
  const productsRef = useRef(null);

  const handleScrollToProducts = useCallback(() => {
    productsRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleStartFreeTrial = useCallback(
    (defaultFeature) => {
      const selectedFeature = {
        ...defaultFeature,
        licenseType: "evaluation",
      };
      navigate("/license", { state: { selectedFeature } });
    },
    [navigate]
  );

  return (
    <div className="flex font-heading py-16 md:pt-0 flex-col min-h-screen bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <main className="flex-1 p-4 md:p-12 lg:p-16">
        <div className="max-w-4xl mx-auto">
          <section className="w-full py-4 md:py-8 lg:py-16 xl:py-24">
            <div className="flex flex-col lg:flex-row justify-between gap-8 lg:gap-4">
              <div className="flex flex-col justify-center space-y-4">
                <div className="space-y-2">
                  <h1 className="text-3xl font-bold tracking-tight sm:text-5xl xl:text-6xl/none text-primary-500 dark:text-primary-400">
                    Boost your productivity with our pioneering products
                  </h1>
                  <p className="max-w-[600px] text-text-700 dark:text-text-300 md:text-xl">
                    Experience our innovative products firsthand to discover how
                    they can boost your productivity with minimal effort. {""}
                    <span className="bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent">
                      Start your free trial today.
                    </span>
                  </p>
                </div>
                <div className="flex gap-2 ">
                  <button onClick={handleScrollToProducts}>
                    <span className="rounded-md bg-gradient-to-br from-teal-500 to-emerald-400 px-2 sm:px-8 py-2 text-sm font-medium shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:text-white hover:scale-105">
                      Start Free Trial
                    </span>
                  </button>
                </div>
              </div>

              <img
                src={chip}
                width="350"
                height="350"
                alt="Hero"
                className="mx-auto aspect-video shadow-md shadow-gray-200 rounded-xl object-cover lg:order-last lg:aspect-auto"
              />
            </div>
            <div ref={productsRef}></div>
          </section>
          <div className="flex flex-col items-center justify-center my-10 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl text-primary-500 dark:text-primary-400">
                <p>Discover the Power of</p>
                <p className="py-1">
                  <span className="bg-gradient-to-r from-purple-600 to-pink-600 text-4xl px-0.5 lg:text-5xl font-bold bg-clip-text text-transparent">
                    SlideCircuitPro
                  </span>
                </p>
              </h2>
              <p className="max-w-[900px] text-text-700 dark:text-text-300 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                SlideCircuitPro offers a rich product suite aimed at optimizing
                work flows, starting from schematic design to generating UPF,
                reducing the effort required at every stage. <br />
                <span className="bg-gradient-to-r from-purple-600 font-medium to-pink-600 bg-clip-text text-transparent">
                  {" "}
                  Evaluate them at no-cost and notice the difference.
                </span>
              </p>
            </div>
          </div>

          <div className="grid gap-12">
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {features.map((item, index) => (
                <div
                  key={index}
                  className="rounded-lg p-6 shadow-lg transition-transform duration-300 ease-in-out hover:-translate-y-2 hover:shadow-xl bg-background-100 dark:bg-background-900"
                >
                  <div className="mb-4">
                    <Link to={item.url}>
                      <h2 className="text-2xl font-bold transition-transform duration-300 hover:text-accent-500 dark:hover:text-accent-400 hover:scale-105 text-primary-500 dark:text-primary-400">
                        {item.name}
                      </h2>
                    </Link>
                    <p className="text-sm text-text-700 dark:text-text-300">{item.description}</p>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <p className="text-accent-500 dark:text-accent-400 font-semibold">
                        {item.trialDuration}
                      </p>
                    </div>
                    <Collapsible features={item.features} />
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={() => handleStartFreeTrial(item)}
                      className="bg-gradient-to-br from-teal-500 to-green-500 text-white px-4 py-2 rounded-md hover:bg-white hover:text-black hover:scale-105"
                    >
                      Start Free Trial
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Evaluation;