import React from "react";
import { Link } from "react-router-dom";
import { legalNotices } from "../data/legalNoticeData";

const LegalNotice = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold text-primary-500 dark:text-primary-300 underline lg:pt-8">
          Legal Notice
        </h1>
        <p className="mt-4 max-w-6xl text-justify">
          To Whom It May Concern,
        </p>
        <p className="mt-4 max-w-6xl text-justify">
          This Legal Notice is issued by Compcarta Solutions Pvt. Ltd.,
          hereinafter referred to as “the Company,” regarding the software
          product “SlideCircuitPro,” hereinafter referred to as “the Software.”
          The Software is protected by intellectual property laws, including but
          not limited to copyright and trademark laws.
        </p>
      </header>
      <div className="max-w-6xl">
        <ul className="list-decimal list-inside space-y-2 text-justify">
          {legalNotices.map((notice, index) => (
            <li key={index}>
              <span className="font-semibold">{notice.title}</span>:{" "}
              {notice.content}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <p className="mt-4 max-w-6xl text-justify">
          This Legal Notice serves as a notice of Compcarta Solutions Pvt Ltd's
          rights and restrictions concerning SlideCircuitPro. Any use,
          reproduction, distribution, or modification of the Software without
          proper authorization may result in legal consequences.
        </p>
        <p className="mt-4 max-w-6xl text-justify">
          For inquiries or further information, please contact:
        </p>
        <p className="mt-4 max-w-6xl text-justify">
          Legal Department, Compcarta Solutions Pvt. Ltd.{" "}
          <Link
            to={"mailto:info@compcarta.com"}
            className="underline text-primary-500 dark:text-primary-300 hover:text-primary-600 dark:hover:text-primary-400"
          >
            [info@compcarta.com]
          </Link>
        </p>
        <p className="mt-4 font-semibold max-w-6xl text-justify">
          Sincerely,
          <br />
          Compcarta Solutions Pvt. Ltd.
        </p>
      </div>
    </div>
  );
};

export default LegalNotice;
