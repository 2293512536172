import React, { useState } from "react";

const ForgotPassword = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isThrottled, setIsThrottled] = useState(false);

  const THROTTLE_TIME = 30000;

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (isThrottled) {
      // Show error if throttled
      setError("Please wait before trying again.");
      setMessage("");
      return;
    }

    setError("");
    setMessage("");
    setLoading(true);

    try {
      const response = await fetch(`${apiURL}/api/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const result = await response.json();

      if (response.ok) {
        setMessage("Password reset link has been sent to your email.");
        setIsThrottled(true);
        setTimeout(() => {
          setIsThrottled(false);
        }, THROTTLE_TIME);
      } else {
        console.log(result);
        setError(result.message || "Something went wrong. Please try again.");
      }
    } catch (error) {
      setError("Failed to connect to the server. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex px-4 sm:px-0 min-h-screen flex-col items-center justify-center bg-gradient-to-br from-primary-500 to-secondary-500 dark:from-primary-900 dark:to-secondary-900">
      <div className="mx-4 w-full max-w-md rounded-lg bg-background-50 dark:bg-background-800 p-8 shadow-xl sm:mx-0">
        <div className="mb-4 flex items-center justify-center">
          <img src="logo.png" width={200} height={200} alt="Compcarta Logo" />
        </div>
        <h2 className="text-center text-xl font-semibold text-text-900 dark:text-text-50">
          Forgot Password
        </h2>
        <p className="text-center text-sm text-text-600 dark:text-text-200 mb-4">
          Please enter your email address to receive a password reset link.
        </p>
        <form className="space-y-4" onSubmit={handleForgotPassword}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-text-900 dark:text-text-50"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="your.email@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="my-1 block w-full rounded-md border-background-300 dark:border-background-600 bg-background-50 dark:bg-background-700 text-text-900 dark:text-text-50 px-3 py-2 text-sm shadow-md focus:border-primary-500 dark:focus:border-primary-400 focus:outline-none focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400"
              required
              aria-describedby="emailHelp"
            />
            <p
              id="emailHelp"
              className="text-xs ml-1 text-text-600 dark:text-text-200"
            >
              We'll send you a link to reset your password.
            </p>
          </div>
          <button
            type="submit"
            className="w-full bg-gradient-to-br from-accent-500 to-accent-600 dark:from-accent-700 dark:to-accent-800 text-white dark:text-text-50 text-md font-semibold py-2 rounded-md shadow-sm hover:text-black dark:hover:text-text-900"
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Reset Link"}
          </button>
        </form>
        {message && (
          <p className="mt-4 text-center text-sm text-green-600 dark:text-green-400">
            {message}
          </p>
        )}
        {isThrottled && (
          <p className="mt-4 text-center text-sm text-red-600 dark:text-red-400">
            {error}
          </p>
        )}
        {error && (
          <p className="mt-4 text-center text-sm text-red-600 dark:text-red-400">
            {error}
          </p>
        )}
        <div className="mt-4 text-center text-sm text-text-600 dark:text-text-200">
          <p>
            Remembered your password?{" "}
            <a
              href="/login"
              className="font-medium text-accent-500 dark:text-accent-400 hover:underline"
            >
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
