export const jobsData = [
    {
      id: 1,
      title: "EDA Software Development Engineer",
      description: "We are looking for a highly motivated software developer for our Development and Engineering team.",
      desiredSkills: [
        "Bachelor's degree in ECE/Computer Science (or similar) is a must",
        "Experience in software programming language (C/C++/C#)",
        "Knowledge of any RTL coding language (Verilog, VHDL, SV)",
        "Digital logic design",
        "Strong communication and problem-solving skills"
      ],
      experience: "0-1 year",
      location: "Greater Noida, UP, India (On-Site)",
      employmentType: "This position is for a full-time candidate. The candidate will be on probation for the first three months.",
      hiringProcess: "Please fill and submit the ",
      formLink: {
        url: "https://forms.gle/AFHpUcJG6kwh31rr5",
        text: "Google form"
      },
      additionalInfo: "It will be followed by a written test and technical interviews. The date of the written test will be communicated via email."
    },
    {
      id: 2,
      title: "VLSI Design Flow & Methodology Engineer",
      description: "We are looking for a highly motivated VLSI engineer, who is motivated to work in complete VLSI Design flow and Methodology. We are looking for a candidate who has worked in VLSI projects during their master's project.",
      desiredSkills: [
        "A master's degree in ECE/VLSI is a must",
        "Knowledge of any one of RTL coding language (Verilog, VHDL, SV) is a must",
        "Prior experience with open-source EDA tools is a plus",
        "Automation skills with Makefile, shell, perl is a plus",
        "Strong communication and problem-solving skills"
      ],
      experience: "0-2 years",
      location: "Greater Noida, UP, India (On-Site)",
      employmentType: "This position is for a full-time candidate. The candidate will be on probation for the first three months.",
      hiringProcess: "Please fill and submit the ",
      formLink: {
        url: "https://forms.gle/8qcQyW7tCwRAZD5r6",
        text: "Google form"
      },
      additionalInfo: "It will be followed by a written test and technical interviews. The date of the written test will be communicated via email."
    }
  ];
  