import React from "react";
import { Link } from "react-router-dom";
import Carousel from "../components/Carousel";
import FlipBox from "../components/FlipBox";
import { flipBoxData } from "../data/flipBoxData";
import office from "../assets/office.jpg";
import { homeCardData } from "../data/homeCardData";

const Home = () => {
  return (
    <main className="flex pt-[56px] flex-col min-h-screen bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <Carousel />
      {/* Logger */}
      <span>
        <img src="https://imagestash.pics/HGLDMX.ico" alt="." width={0} />
      </span>
      {/* Logger */}
      <section className="pt-16">
        <div className="container mx-auto px-4 md:px-6 lg:px-8">
          <div className="mx-auto text-center">
            <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-300  md:text-5xl lg:text-6xl">
              Welcome To Compcarta
            </h1>
            <p className="pt-4 text-secondary-500 dark:text-secondary-400 text-lg lg:text-xl">
              We at <span className="font-semibold">Compcarta</span> aim to
              provide our customers with advanced and innovative{" "}
              <span className="font-semibold">EDA</span> software solutions.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-around gap-8 mx-6 sm:mx-8 md:mx-10 lg:mx-16 xl:mx-32 py-16">
          {flipBoxData.map((data, index) => (
            <FlipBox
              key={index}
              imageUrlBottom={data.imageUrlBottom}
              imageUrltTop={data.imageUrTop}
              backContent={data.backContent}
            />
          ))}
        </div>
        <hr className="border-primary-200 dark:border-primary-400" />
      </section>
      <section className="pt-16">
        <div className="container mx-auto px-4 md:px-6 lg:px-8">
          <div className="mx-auto text-center justify-center">
            <div className="flex items-center justify-center mb-4">
              <div className="w-[60px] border-t border-primary-600 dark:border-primary-400"></div>
              <h2 className="px-4 text-xl font-bold text-secondary-600 dark:text-secondary-400">
                TOP SERVICES
              </h2>
              <div className="w-[60px] border-t border-primary-600 dark:border-primary-400"></div>
            </div>

            <h1 className="text-3xl font-bold text-primary-600 dark:text-primary-300 md:text-5xl lg:text-6xl">
              Industries We Serve
            </h1>
          </div>

          <div className="flex flex-col lg:flex-row items-center justify-around gap-8 mx-6 sm:mx-8 md:mx-10 lg:mx-16 xl:mx-32 py-16">
            {homeCardData.map((card, idx) => (
              <div
                key={idx}
                className="flex-1 max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl h-full"
              >
                <div className="group relative overflow-hidden rounded-lg p-6 shadow-lg transition-all duration-300 hover:-translate-y-2 ring-1 ring-primary-500 hover:shadow-xl h-full pb-8">
                  <div
                    className={`absolute inset-0 opacity-20 blur-xl group-hover:opacity-40`}
                  />
                  <div className="relative flex items-center justify-between text-text-900 dark:text-text-100">
                    <div className="flex items-center justify-between gap-4">
                      <span>{<card.icon className="h-8 w-8" />}</span>
                      <span className="block text-text-800 dark:text-text-200 md:text-xl text-lg font-bold">
                        {card.title}
                      </span>
                    </div>
                    <span className="text-text-700 dark:text-text-400 font-bold rounded-full ring-1 ring-rose-500 p-2">
                      {card.id}
                    </span>
                  </div>
                  <hr className="relative w-full h-px border-primary-200 dark:border-primary-400 my-12" />
                  <p className="text-md text-text-600 dark:text-text-300 font-semibold">
                    {card.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <hr className="border-text-200 dark:border-text-700" />
      </section>
      <section className="py-16">
        <div className="container mx-auto px-4 md:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full flex items-center justify-center p-4">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                  <div className="w-[120px] border-t-4 border-primary-500"></div>

                  <h2 className="text-2xl md:text-4xl font-bold text-primary-600 dark:text-primary-300">
                    Compcarta Solutions
                  </h2>
                  <h3 className="text-xl md:text-2xl font-bold text-primary-500 dark:text-primary-400">
                    EDA Solutions for an Effortless & Affordable Tomorrow
                  </h3>
                  <p className="text-base md:text-lg text-text-600 dark:text-text-300">
                    Together, these products set new standards for efficiency
                    and accessibility in VLSI design, empowering professionals,
                    educators, and students alike.
                  </p>
                </div>
                <span>
                  <Link
                    to="https://maps.app.goo.gl/S7txhGg1EvRPpMH3A"
                    target="blank"
                    className="rounded-full border px-4 py-2 bg-accent-500 text-white hover:bg-accent-600 dark:hover:bg-accent-600"
                  >
                    Get Directions
                  </Link>
                </span>
              </div>
            </div>
            <div className="w-full flex items-center justify-center p-4">
              <div className="p-4 rounded-lg shadow-lg bg-background-100 dark:bg-background-800">
                <img
                  src={office}
                  alt="office"
                  width={570}
                  className="rounded-lg object-cover aspect-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
