import React, { useState } from "react";
import { motion } from "framer-motion";
import { slides } from "../data/slideData";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  return (
    <div className=" w-full relative">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`carousel-item relative w-full ${
            index === currentSlide ? "" : "hidden"
          }`}
        >
          <div className="w-full h-64 sm:h-80 md:h-96 lg:h-[32rem] flex justify-center items-center">
            <img
              src={slide.imgUrl}
              className="w-full h-full brightness-80"
              alt={`Slide ${index + 1}`}
            />
          </div>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="absolute bottom-8 md:bottom-32 text-white left-16 right-16 text-center"
          >
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-2xl lg:text-4xl xl:text-6xl font-extrabold mb-2 bg-gradient-to-r from-purple-600 via-pink-600 to-blue-600 bg-clip-text text-transparent"
            >
              {slide.title}
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-lg lg:text-lg xl:text-xl font-extrabold mb-8 bg-gradient-to-r  from-amber-600 via-red-600 to-pink-600 bg-clip-text text-transparent"
            >
              {slide.description}
            </motion.p>
            <motion.a
              href={slide.link}
              target="_blank"
              rel="noopener noreferrer"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="text-white uppercase font-bold py-2 px-4 rounded-full ring-2 ring-indigo-600"
            >
              Learn More
            </motion.a>
          </motion.div>
          <div className="absolute left-5 right-5 top-1/2 flex -translate-y-1/2 transform items-center justify-between text-text-900 dark:text-text-200">
            <button
              onClick={prevSlide}
              className="text-3xl md:text-4xl bg-background-100 dark:bg-background-800 opacity-90 rounded-full ring-1 hover:ring ring-primary-500 dark:ring-primary-400 transition duration-300"
            >
              <GrFormPrevious className="hover:scale-125" />
            </button>
            <button
              onClick={nextSlide}
              className="text-3xl md:text-4xl bg-background-100 dark:bg-background-800 opacity-90 rounded-full ring-1 hover:ring ring-primary-500 dark:ring-primary-400 transition duration-300"
            >
              <GrFormNext className="hover:scale-125" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Carousel;
