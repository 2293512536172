import React from "react";

const UnderDevelopment = () => {
  return (
    <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200 px-4 py-12 sm:px-6 lg:px-8">
      <div className="mx-auto max-w-md text-center">
        <div className="relative inline-block">
          <div className="absolute -top-4 -right-4 h-8 w-8 animate-pulse rounded-full bg-accent-500 dark:bg-accent-400 " />
          <div className="absolute -bottom-4 -left-4 h-8 w-8 animate-pulse rounded-full bg-accent-500 dark:bg-accent-400" />
          <h1 className="text-3xl font-bold text-primary-500 dark:text-primary-400 tracking-tight sm:text-4xl">
            Under Development
          </h1>
        </div>
        <p className="mt-4 text-text-700 dark:text-text-300 ">
          We're working hard to bring you an amazing new experience. Please
          check back soon!
        </p>
      </div>
    </div>
  );
};

export default UnderDevelopment;
