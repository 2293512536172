import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SubmitTicketContent = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const user = useSelector((state) => state.auth.user);

  const [ticketId, setTicketId] = useState("");

  const [formData, setFormData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    subject: "",
    details: "",
    department: "",
    product: "",
    priority: "",
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({});
    }
  };

  const validateForm = () => {
    let formErrors = {};

    // Validate subject length
    if (!formData.subject) {
      formErrors.subject = "Subject is required.";
    } else if (formData.subject.length < 10) {
      formErrors.subject = "Subject must be at least 10 characters long.";
    } else if (formData.subject.length > 100) {
      formErrors.subject = "Subject cannot be more than 100 characters long.";
    }

    // Validate details length
    if (!formData.details) {
      formErrors.details = "Details are required.";
    } else if (formData.details.length < 20) {
      formErrors.details = "Details must be at least 20 characters long.";
    } else if (formData.details.length > 1000) {
      formErrors.details = "Details cannot be more than 1000 characters long.";
    }

    if (!formData.department) {
      formErrors.department = "Please select a department.";
    }

    if (!formData.product) {
      formErrors.product = "Please select a product.";
    }

    if (!formData.priority) {
      formErrors.priority = "Please select a priority level.";
    }

    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    setErrors({});

    try {
      const token = localStorage.getItem("token"); // Retrieve the token

      const response = await axios.post(
        `${apiURL}/api/support-tickets`,
        formData,
        {
          headers: {
            "x-auth-token": token, // Set the token in the request headers
          },
        }
      );

      if (response.status === 201) {
        setTicketId(response.data.ticketId);
        setIsSubmitted(true);
        setFormData({
          subject: "",
          details: "",
          department: "",
          product: "",
          priority: "",
        });
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        // Server responded with a status other than 2xx
        setErrors({
          form:
            err.response.data.error || "An error occurred. Please try again.",
        });
      } else if (err.request) {
        // Request was made but no response received
        setErrors({
          form: "No response from the server. Please check your connection.",
        });
      } else {
        // Something happened in setting up the request
        setErrors({
          form: "An unexpected error occurred. Please try again.",
        });
      }
    } finally {
      setIsLoading(false); // Set loading to false after the request is complete
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Submit a New Ticket</h2>
      {isSubmitted ? (
        <div className="text-green-600 text-center mb-4">
          Your ticket has been submitted successfully!
          <br /> <span className="text-gray-900 font-semibold">
            Ticket ID:
          </span>{" "}
          <span className="font-semibold text-rose-500">{ticketId}</span>
          <br />
          <br />
          <span className="text-gray-900 font-medium">
            To see your ticket details,{" "}
            <Link
              to={"/account/support-tickets"}
              className="text-blue-400 underline hover:text-indigo-700"
            >
              click here
            </Link>
          </span>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Subject</label>
            <input
              type="text"
              name="subject"
              placeholder="Brief description of your issue"
              className={`mt-2 p-2 border rounded-md w-full ${
                errors.subject ? "border-red-500" : ""
              }`}
              value={formData.subject}
              onChange={handleChange}
            />
            {errors.subject && (
              <p className="text-red-500 text-sm mt-1">{errors.subject}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Details</label>
            <textarea
              name="details"
              placeholder="Provide detailed information about your issue"
              className={`mt-2 p-2 border rounded-md w-full ${
                errors.details ? "border-red-500" : ""
              }`}
              rows="5"
              value={formData.details}
              onChange={handleChange}
            ></textarea>
            {errors.details && (
              <p className="text-red-500 text-sm mt-1">{errors.details}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Department</label>
            <select
              name="department"
              className={`mt-2 p-2 border rounded-md w-full ${
                errors.department ? "border-red-500" : ""
              }`}
              value={formData.department}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select a department
              </option>
              <option>Billing Issue</option>
              <option>Sales</option>
              <option>Support</option>
              <option>Technical Team</option>
            </select>
            {errors.department && (
              <p className="text-red-500 text-sm mt-1">{errors.department}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Product</label>
            <select
              name="product"
              className={`mt-2 p-2 border rounded-md w-full ${
                errors.product ? "border-red-500" : ""
              }`}
              value={formData.product}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select a product
              </option>
              <option>SlideCircuit</option>
              <option>SlideWave</option>
              <option>SlideSystem</option>
              <option>SlideRTL</option>
              <option>SlideUPF</option>
            </select>
            {errors.product && (
              <p className="text-red-500 text-sm mt-1">{errors.product}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Priority</label>
            <select
              name="priority"
              className={`mt-2 p-2 border rounded-md w-full ${
                errors.priority ? "border-red-500" : ""
              }`}
              value={formData.priority}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select priority level
              </option>
              <option>Low</option>
              <option>Medium</option>
              <option>High</option>
            </select>
            {errors.priority && (
              <p className="text-red-500 text-sm mt-1">{errors.priority}</p>
            )}
          </div>
          {errors.form && (
            <div className="text-red-500 text-center mb-4">{errors.form}</div>
          )}
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-4 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit Ticket"}
          </button>
        </form>
      )}
    </div>
  );
};
export default SubmitTicketContent;
