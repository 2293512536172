import React from "react";

const ShippingAndDeliveryCondition = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold text-primary-500 dark:text-primary-400 underline lg:pt-8">
          Shipping and Delivery Condition
        </h1>
      </header>
      <div className="max-w-6xl w-full">
        <section className="mt-8">
          <p className="mt-4 text-justify">
            Compcarta Solutions Private Limited (“Compcarta”) provides software
            services, including SlideCircuitPro (“Software”), which are
            delivered exclusively online. Therefore, there are no physical
            shipments or deliveries associated with the acquisition of the
            Software.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            Online Access
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Access to the Software is provided through online channels only,
            accessible via the internet.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            License Delivery
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            License keys and access credentials for the Software are delivered
            electronically, typically via email or through the user’s online
            account dashboard.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            Updates and Support
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Software updates, customer support, and related services are also
            provided online through our digital platform.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            No Physical Products
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Compcarta does not ship or deliver physical products associated with
            the Software acquisition.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            Contact Information
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            For any questions or assistance regarding access to the Software or
            related services, please contact our customer support team at:
          </p>
          <div className="font-semibold">
            <p className="mt-4 text-justify text-text-700 dark:text-text-300">
              Email:{" "}
              <a
                href="mailto:Info@compcarta.com"
                className="underline text-accent-500 dark:text-accent-400"
              >
                info@compcarta.com
              </a>
            </p>
            <p className="mt-4 text-justify text-text-700 dark:text-text-300">
              Compcarta Solutions Pvt. Ltd.
              <br />
              A-50, Ecotech - VI, Greater Noida, U.P., 201310
              <br />
              02 Oct, 2023
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ShippingAndDeliveryCondition;
