import { motion } from "framer-motion";
import React from "react";
import { FaWindows } from "react-icons/fa";
import { MdDownload } from "react-icons/md";
import { Link } from "react-router-dom";

const Downloads = () => {
  return (
    <div className="p-4 pt-20 lg:p-24 flex flex-col items-center justify-center text-background-900 dark:text-background-100 bg-background-50 dark:bg-background-950">
      <header className="text-center max-w-6xl mb-8">
        <h1 className="text-4xl font-bold text-primary-900 dark:text-primary-100 mb-4">
          Download and Install{" "}
          <span className="bg-gradient-to-r from-primary-600 to-accent-600 dark:from-primary-400 dark:to-accent-400 px-0.5 font-bold bg-clip-text text-transparent">
            SlideCircuitPro
          </span>
        </h1>
        <span className="text-lg text-secondary-700 dark:text-secondary-200">
          Follow the steps below to download, install, and keep your add-in up to date.
        </span>
      </header>

      <main className="w-full max-w-4xl">
        <section className="bg-background-100 dark:bg-background-900 p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-2xl font-semibold text-primary-800 dark:text-primary-200 mb-4">
            Step 1: Download the Installer
          </h2>
          <div className="flex flex-col lg:flex-row gap-6">
            <motion.div
              className="flex-1 bg-primary-50 dark:bg-primary-800 p-4 rounded-lg shadow-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="flex items-center mb-2">
                <FaWindows className="text-3xl text-accent-600 dark:text-accent-400 mr-2" />
                <h3 className="text-xl font-semibold text-primary-900 dark:text-primary-100">Windows</h3>
              </div>
              <p className="text-secondary-700 dark:text-secondary-300 mb-4">
                Click the button below to download the installer for Windows. Ensure that your system meets the requirements listed in the installation guide.
              </p>
              <a
                href="/downloads/setup.exe"
                download
                className="inline-flex items-center bg-accent-500 dark:bg-accent-400 text-white px-4 py-2 rounded-lg hover:bg-accent-600 dark:hover:bg-accent-500"
              >
                <MdDownload className="mr-2" /> Download for Windows
              </a>
            </motion.div>
          </div>
        </section>

        <section className="bg-background-100 dark:bg-background-900 p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-2xl font-semibold text-primary-800 dark:text-primary-200 mb-4">
            Step 2: Install the Add-in
          </h2>
          <div className="text-secondary-700 dark:text-secondary-300">
            <p className="mb-4">
              After downloading, run the installer. Follow the on-screen instructions to complete the installation.
            </p>
            <p className="mb-4">
              Make sure to close all instances of PowerPoint before installing. Once installed, you can start PowerPoint, and the add-in will be ready to use.
            </p>
            <p>
              If you encounter any issues during installation, please{" "}
              <Link
                to={"/support"}
                className="text-accent-500 dark:text-accent-300 hover:underline"
              >
                contact our support team
              </Link>
              .
            </p>
          </div>
        </section>

        <section className="bg-background-100 dark:bg-background-900 p-6 rounded-lg shadow-lg mb-8">
          <h2 className="text-2xl font-semibold text-primary-800 dark:text-primary-200 mb-4">
            Step 3: Stay Up-to-Date
          </h2>
          <div className="text-secondary-700 dark:text-secondary-300">
            <p className="mb-4">
              We regularly update our add-in to provide new features and improvements. To stay up-to-date, visit this page periodically to download the latest version.
            </p>
            <p className="mb-4">
              You will be notified via email when a new update is available. If you prefer, you can also check for updates manually by visiting this page.
            </p>
            <p>
              Simply download the latest installer and run it. The installer will automatically update your existing installation.
            </p>
          </div>
        </section>
      </main>

      <footer className="text-center mt-12">
        <p className="text-secondary-600 dark:text-secondary-400">
          If you encounter any issues or need assistance, please{" "}
          <Link
            to={"/support"}
            className="text-accent-500 dark:text-accent-300 hover:underline"
          >
            contact our support team
          </Link>
          .
        </p>
      </footer>
    </div>
  );
};

export default Downloads;
