import React from "react";
import image from "../../assets/businext-img13.jpg";
import circle from "../../assets/circles.png";
import { GiArchiveResearch } from "react-icons/gi";
import { GiAtom } from "react-icons/gi";
import { FaGears } from "react-icons/fa6";
import { FiCheckCircle } from "react-icons/fi";

export default function Partners() {
  return (
    <div className="flex flex-col min-h-screen bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="container mx-auto py-16 text-center">
          <h2 className="text-4xl font-extrabold text-primary-500 dark:text-primary-400 mb-8">
            Partners
          </h2>
          <p className="text-2xl font-semibold text-secondary-500 dark:text-secondary-400">
            Partner with{" "}
            <span className="text-accent-500 dark:text-accent-400 font-bold">
              Compcarta Solutions Pvt. Ltd.
            </span>
          </p>
          <p className="text-lg my-4 text-text-700 dark:text-text-300 text-justify">
            At Compcarta Solutions, we value collaborative partnerships that
            drive innovation and foster growth. We believe in the power of
            strategic alliances to create a holistic ecosystem that empowers
            businesses and individuals to achieve their goals. By joining forces
            with leading technology providers, universities, and research
            institutions, we aim to push the boundaries of Electronic Design
            Automation (EDA) and make a meaningful impact in the industry.
          </p>

          <div className="my-4 lg:p-32 lg:-my-16">
            <img src={image} alt="Team collaboration" className="rounded-lg" />
          </div>
        </div>

        <hr className="border-t border-primary-200 dark:border-primary-400" />

        <div className="py-16">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row justify-around items-center text-center md:text-left space-y-8 md:space-y-0 md:space-x-8">
              {[
                {
                  title: "01. Elevate Your Vision with Our Expertise",
                  description:
                    "With a focus on precision and excellence, our team is committed to elevating your vision through our specialized expertise. Whether you are seeking innovative solutions for your electronic designs or looking to optimize your current workflow, we provide the necessary guidance and tools to help you achieve your goals.",
                  color: "from-[#7928CA] to-[#FF0080]",
                },
                {
                  title:
                    "02. Customized Solutions for Your Specialized Requirements",
                  description:
                    "Our commitment to feasibility and adaptability means that we are well-equipped to undertake further development to meet your specific demands, ensuring that our solutions evolve with your changing requirements.",
                  color: "from-[#FF6B6B] to-[#FFD93D]",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex-1 max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl h-full  "
                >
                  <div className="group relative border-[1px] border-primary-500  overflow-hidden rounded-lg p-6 shadow-lg transition-all duration-300 hover:-translate-y-2 hover:shadow-xl h-full">
                    <div
                      className={`absolute inset-0 z-0 bg-gradient-to-r ${item.color} opacity-20 blur-xl group-hover:opacity-40`}
                    />
                    <div className="relative z-10 flex flex-col items-center justify-center gap-4 h-full">
                      <h3 className="text-xl font-semibold text-center text-primary-700 dark:text-primary-500">
                        {item.title}
                      </h3>
                      <p className="text-text-700 dark:text-text-300 text-justify">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <hr className="border-t border-primary-200 dark:border-primary-400" />

        <div className="container mx-auto py-16 text-center">
          <h3 className="text-3xl font-bold text-primary-500 dark:text-primary-400 mb-8">
            Our Flawless Process
          </h3>
          <p className="text-lg mb-8 text-text-700 dark:text-text-300">
            Our streamlined design process is a hallmark of our commitment to
            efficient and effective design solutions. Our focus on a streamlined
            workflow is aimed at enhancing productivity and enabling users to
            achieve their design goals with precision and ease.
          </p>
          <div className="flex flex-col md:flex-row justify-around items-center">
            {[
              { icon: <GiArchiveResearch />, title: "Research" },
              { icon: <GiAtom />, title: "Develop" },
              { icon: <FaGears />, title: "Testing" },
              { icon: <FiCheckCircle />, title: "Launch" },
            ].map((item, id) => (
              <div
                key={id}
                className="flex relative flex-col p-12 items-center justify-center"
              >
                <img src={circle} alt="circleSVG" className="absolute" />
                <div className="text-4xl text-accent-500 dark:text-accent-400 mb-4">
                  {item.icon}
                </div>
                <p className="font-semibold text-primary-700 dark:text-primary-500">
                  {item.title}
                </p>
              </div>
            ))}
          </div>
        </div>

        <hr className="border-t border-gray-300 dark:border-gray-700" />
      </div>

      {/* <section className="w-full py-12 md:py-24 lg:py-32 bg-background-200 dark:bg-background-800">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <div className="space-y-4">
            <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-primary-700 dark:text-primary-500">Our Valued Partners</h1>
            <p className="max-w-xl mx-auto text-text-700 dark:text-text-300 md:text-xl">
              We are proud to collaborate with industry-leading companies to deliver exceptional solutions to our customers.
            </p>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6 mt-12">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="flex items-center justify-center ring">
                <img
                  src="/logo.png"
                  width="140"
                  height="70"
                  alt="Partner Logo"
                  className="aspect-[2/1] object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid gap-12 lg:grid-cols-2">
            <div className="space-y-4">
              <div className="inline-block rounded-lg bg-background-200 dark:bg-background-800 px-3 py-1 text-sm text-primary-700 dark:text-primary-500">
                Key Partnerships
              </div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-primary-700 dark:text-primary-500">Collaborating for Success</h2>
              <p className="max-w-xl text-text-700 dark:text-text-300 md:text-xl leading-relaxed lg:text-base xl:text-xl">
                We have forged strategic partnerships with industry leaders to provide our customers with the best possible solutions.
              </p>
            </div>
            <div className="grid gap-6">
              {[
                {
                  title: "Compcarta",
                  description:
                    "Our partnership with Acme Inc. allows us to leverage their cutting-edge technology and expertise to deliver innovative solutions to our customers.",
                },
                {
                  title: "EDA",
                  description:
                    "By collaborating with XYZ Corporation, we can offer our customers a wider range of products and services, ensuring they have access to the best-in-class solutions.",
                },
                {
                  title: "SlideCircuit",
                  description:
                    "Our partnership with Global Tech allows us to leverage their global reach and expertise to deliver seamless solutions to our customers worldwide.",
                },
              ].map((partner, i) => (
                <div key={i} className="grid gap-2">
                  <h3 className="text-xl font-bold text-primary-700 dark:text-primary-500">{partner.title}</h3>
                  <p className="text-text-700 dark:text-text-300">{partner.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-12 md:py-24 lg:py-32 bg-background-200 dark:bg-background-800">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid gap-12 lg:grid-cols-2">
            <div className="space-y-4">
              <div className="inline-block rounded-lg bg-background-200 dark:bg-background-800 px-3 py-1 text-sm text-primary-700 dark:text-primary-500">
                Global Presence
              </div>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-primary-700 dark:text-primary-500">Industry-Leading Expertise</h2>
              <p className="max-w-xl text-text-700 dark:text-text-300 md:text-xl leading-relaxed lg:text-base xl:text-xl">
                Our global network of partners allows us to deliver unparalleled expertise to customers around the world.
              </p>
            </div>
            <div className="grid gap-6">
              {[
                {
                  title: "SlideEDU",
                  description:
                    "We offer comprehensive training programs and educational resources to our partners, ensuring they have the skills and knowledge to succeed.",
                },
                {
                  title: "EDA Simulation",
                  description:
                    "Our extensive range of EDA Simulation tools enables our partners to design, develop, and optimize their products with ease.",
                },
                {
                  title: "Testing Solutions",
                  description:
                    "We provide our partners with a suite of testing solutions that help ensure the reliability and quality of their products.",
                },
              ].map((partner, i) => (
                <div key={i} className="grid gap-2">
                  <h3 className="text-xl font-bold text-primary-700 dark:text-primary-500">{partner.title}</h3>
                  <p className="text-text-700 dark:text-text-300">{partner.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}
