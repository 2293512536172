import React from "react";
import { Link } from "react-router-dom";
import { jobsData } from "../../data/careersData";

const Careers = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold text-primary-500 dark:text-primary-400">
          Career
        </h1>
        <h2 className="text-2xl font-semibold text-secondary-500 dark:text-secondary-400">
          Be A Part Of Our{" "}
          <span className="text-accent-500 dark:text-accent-400">Team</span>
        </h2>
        <p className="mt-4 text-text-700 dark:text-text-300 max-w-6xl text-justify">
          We are an innovative Electronic Design Automation (EDA) Startup
          dedicated to creating cutting-edge software solutions. Our current
          focus involves seamlessly integrating circuit design capabilities into
          an existing easily available tool, revolutionizing the workflow for
          VLSI engineers. The product is developed and poised for customer
          deployment, encompassing a broad spectrum of VLSI design aspects.
        </p>
      </header>
      {jobsData.map((job) => (
        <section key={job.id} className="mb-8 max-w-6xl">
          <h3 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            Job Profile: {job.title}
          </h3>
          <p className="mt-4 text-text-700 dark:text-text-300">
            {job.description}
          </p>
          <div className="mt-4">
            <h4 className="font-semibold text-primary-700 dark:text-primary-500">
              Desired skills:
            </h4>
            <ul className="list-disc list-inside text-text-700 dark:text-text-300">
              {job.desiredSkills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </div>
          <p className="mt-4 text-text-700 dark:text-text-300">
            Desired Experience: {job.experience}
          </p>
          <p className="mt-4 text-text-700 dark:text-text-300">
            Location: {job.location}
          </p>
          <p className="mt-4 text-text-700 dark:text-text-300">
            Employment Type: {job.employmentType}
          </p>
          <p className="mt-4 text-text-700 dark:text-text-300">
            Hiring Process: {job.hiringProcess}{" "}
            <Link
              to={job.formLink.url}
              target="_blank"
              className="text-accent-500 dark:text-accent-400 underline"
            >
              {job.formLink.text}
            </Link>
            . {job.additionalInfo}
          </p>
        </section>
      ))}
    </div>
  );
};

export default Careers;
