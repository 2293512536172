import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

// Modal Component
const Modal = ({ isOpen, onClose, ticket }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg max-h-[80vh] ">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-semibold">Ticket Details</h2>
          <button
            onClick={onClose}
            className=" bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
          >
            X
          </button>
        </div>
        <div className="mb-2">
          <strong>Ticket ID:</strong> {ticket.ticketId}
        </div>
        <div className="mb-2">
          <strong>Subject:</strong>
          <span className="text-justify text-base font-[500]"> {ticket.subject}</span>
        </div>
        <div className="mb-2 flex items-center justify-between">
          <span>
            <strong>Department:</strong> {ticket.department}
          </span>
          <span>
            <strong>Product:</strong> {ticket.product}
          </span>
        </div>
        <div className="mb-2 flex items-center justify-between">
          <span>
            <strong>Status:</strong> {ticket.status}
          </span>
          <span>
            <strong>Priority:</strong> {ticket.priority}
          </span>
        </div>
        <div className="mb-2">
          <strong>Date Submitted:</strong>{" "}
          {moment(ticket.createdAt).format("DD/MM/YYYY")}
        </div>
        <hr className="my-2 border-t-2 border-gray-200" />
        <div className="mb-2">
          <strong>Description:</strong>
          <p className="text-justify max-h-40 overflow-y-auto pr-4 mt-2">
            {ticket.details}
          </p>
        </div>
        {/* Add more content here if needed */}
      </div>
    </div>
  );
};

const SupportTicketsContent = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const user = useSelector((state) => state.auth.user);
  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTicket, setSelectedTicket] = useState(null); // State for selected ticket
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    if (user && user.id) {
      const fetchTickets = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            `${apiURL}/api/support-tickets/all`,
            {
              headers: {
                "x-auth-token": token,
              },
            }
          );
          setTickets(response.data.tickets);
        } catch (err) {
          setError("Failed to fetch tickets.");
        } finally {
          setIsLoading(false);
        }
      };

      fetchTickets();
    }
  }, [apiURL, user]);

  const handleViewDetails = (ticket) => {
    setSelectedTicket(ticket); // Set the selected ticket data
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        Support Tickets
      </h2>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-sm">
            <thead>
              <tr className="bg-indigo-100 text-gray-800">
                <th className="py-3 px-6 border-b-2 text-left text-sm font-semibold">
                  Ticket ID
                </th>
                <th className="py-3 px-6 border-b-2 text-left text-sm font-semibold">
                  Subject
                </th>
                <th className="py-3 px-6 border-b-2 text-left text-sm font-semibold">
                  Status
                </th>
                <th className="py-3 px-6 border-b-2 text-left text-sm font-semibold">
                  Date Submitted
                </th>
                <th className="py-3 px-6 border-b-2"></th>
              </tr>
            </thead>
            <tbody>
              {tickets.length > 0 ? (
                tickets.map((item, index) => (
                  <tr
                    key={item.ticketId}
                    className={`${
                      index % 2 === 0 ? "bg-teal-50" : "bg-white"
                    } hover:bg-gray-100 transition-colors duration-200`}
                  >
                    <td className="py-3 px-6 border-b text-sm text-blue-400 cursor-pointer hover:underline hover:text-blue-600"  onClick={() => handleViewDetails(item)}>
                      {item.ticketId}

                    </td>
                    <td className="py-3 px-6 border-b text-sm text-gray-800">
                      {item.subject}
                    </td>
                    <td className="py-3 px-6 border-b text-sm text-gray-800">
                      {item.status}
                    </td>
                    <td className="py-3 px-6 border-b text-sm text-gray-800">
                      {moment(item.createdAt).format("DD/MM/YYYY")}
                    </td>
                    {/* <td
                      className="py-3 px-6 border-b text-sm text-blue-600 hover:underline cursor-pointer"
                      onClick={() => handleViewDetails(item)}
                    >
                      View Details
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    className="py-4 px-6 text-center text-sm text-gray-600"
                  >
                    No tickets found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal for ticket details */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        ticket={selectedTicket}
      />
    </div>
  );
};

export default SupportTicketsContent;
