// src/redux/authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {jwtDecode} from "jwt-decode";
import axios from "axios";

const initialState = {
  user: null,
  token: localStorage.getItem("token") || null,
  error: null,
};

if (initialState.token) {
  try {
    const decodedToken = jwtDecode(initialState.token);
    initialState.user = {
      id: decodedToken.user.id,
      firstName: decodedToken.user.firstName,
      lastName: decodedToken.user.lastName,
      username: decodedToken.user.username,
      email: decodedToken.user.email,
      phone: decodedToken.user.phone,
      gender: decodedToken.user.gender,
      userType: decodedToken.user.userType,
      country: decodedToken.user.country,
      createdAt: decodedToken.user.createdAt,
    };
  } catch (error) {
    console.error("Invalid token", error);
    localStorage.removeItem("token");
    initialState.token = null;
    initialState.user = null;
  }
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      localStorage.setItem("token", state.token);
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem("token");
    },
    updateUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { login, logout, updateUser, setError } = authSlice.actions;

export const updateUserDetails = (userData) => async (dispatch) => {
  try {
    const { data } = await axios.put("/api/users/profile", userData);
    dispatch(updateUser(data.user));
  } catch (error) {
    dispatch(setError(error.response?.data?.message || "Failed to update user details"));
  }
};

export const changePassword = (passwords) => async (dispatch) => {
  try {
    await axios.post("/api/users/change-password", passwords);
  } catch (error) {
    dispatch(setError(error.response?.data?.message || "Failed to change password"));
  }
};

export const deleteUserAccount = () => async (dispatch) => {
  try {
    await axios.delete("/api/users/profile");
    dispatch(logout());
  } catch (error) {
    dispatch(setError(error.response?.data?.message || "Failed to delete account"));
  }
};

export default authSlice.reducer;
