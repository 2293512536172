import React from "react";
import ProductsCard from "../components/ProductsCard";
import { featuresData, productsData } from "../data/productData";

export default function Products() {
  return (
    <div className="flex flex-col items-center p-4 pt-20 space-y-8 md:space-y-12 md:p-8 lg:p-24 bg-background-100 dark:bg-background-900 text-text-800 dark:text-text-100">
      <section className="text-center z-10">
        <h1 className="text-4xl font-bold">
          Explore Our Best <span className="text-primary-600 dark:text-primary-400">Products</span>
        </h1>
      </section>

      <section className="flex flex-col items-center space-y-8 md:flex-row md:space-y-0 md:space-x-8 px-2 max-w-6xl">
        <div className="space-y-8">
          {productsData.slice(0, 2).map((item, idx) => {
            return (
              <div key={idx}>
                <ProductsCard
                  color={item.color}
                  hover={item.hover}
                  Icon={item.icon}
                  path={item.path}
                  title={item.title}
                  description={item.description}
                />
              </div>
            );
          })}
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-center z-0 p-4">
            <div className="relative text-lg font-bold text-text-700 dark:text-text-200 italic flex flex-col items-center justify-center p-6 space-y-4 bg-accent-100 dark:bg-accent-800 ring-1 dark:ring-accent-700 rounded-full w-80 h-80">
              <h2 className="text-primary-500 dark:text-primary-400 text-xl font-bold underline">
                Key Features
              </h2>
              <h3 className="hover:text-primary-600 dark:hover:text-primary-300 transition-colors duration-200">
                Presentation Tool Integration
              </h3>
              <h3 className="hover:text-primary-600 dark:hover:text-primary-300 transition-colors duration-200">
                VLSI Design Expertise
              </h3>
              <h3 className="hover:text-primary-600 dark:hover:text-primary-300 transition-colors duration-200">
                User-Centric Approach
              </h3>
              <h3 className="hover:text-primary-600 dark:hover:text-primary-300 transition-colors duration-200">
                Enhanced Productivity
              </h3>
            </div>
          </div>
          {productsData.slice(2, 3).map((item, idx) => {
            return (
              <div key={idx}>
                <ProductsCard
                  color={item.color}
                  hover={item.hover}
                  Icon={item.icon}
                  path={item.path}
                  title={item.title}
                  description={item.description}
                />
              </div>
            );
          })}
        </div>

        <div className="space-y-8">
          {productsData.slice(3, 5).map((item, idx) => {
            return (
              <div key={idx}>
                <ProductsCard
                  color={item.color}
                  hover={item.hover}
                  Icon={item.icon}
                  path={item.path}
                  title={item.title}
                  description={item.description}
                />
              </div>
            );
          })}
        </div>
      </section>

      <section>
        <div className="max-w-6xl pt-12">
          <h2 className="text-2xl font-bold text-primary-500 dark:text-primary-400 underline">
            Features and Benefits
          </h2>
          <ol className="list-decimal list-inside mt-4 space-y-2 text-justify">
            {featuresData.map((item, index) => {
              return (
                <li key={index} className="hover:text-primary-600 dark:hover:text-primary-300 transition-colors duration-200">
                  <span className="font-semibold">{item.split(":", 1)}</span>
                  {": "}
                  {item.split(":")[1]}
                </li>
              );
            })}
          </ol>
        </div>
      </section>
    </div>
  );
}
