import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const verifyToken = async () => {
        try {
          const response = await fetch(
            `${apiURL}/api/auth/verify-email?token=${token}`,
            {
              method: "GET",
            }
          );

          const result = await response.json();

          if (response.ok) {
            setMessage("Email verified successfully. You can now log in.");
            setTimeout(() => navigate("/login"), 5000);
          } else {
            setMessage(result.message || "Email verification failed.");
          }
        } catch (error) {
          setMessage(
            "Failed to connect to the server. Please try again later."
          );
        } finally {
          setIsLoading(false);
        }
      };

      verifyToken();
    } else {
      setMessage("Invalid or missing token.");
      setIsLoading(false);
    }
  }, [token, navigate, apiURL]);

  return (
    <div className="flex pt-24 px-4 sm:px-0 min-h-screen flex-col items-center justify-center bg-gradient-to-br from-primary to-secondary">
      <div className="mx-4 m-12 w-full max-w-md rounded-lg bg-white p-8 shadow-xl sm:mx-0">
        <div className="mb-8 flex items-center justify-center">
          <img src="logo.png" width={200} height={200} alt="logo" />
        </div>
        {isLoading ? (
          <p className="text-center text-sm text-gray-600">
            Verifying email...
          </p>
        ) : (
          <p className="text-center text-sm text-gray-600">{message}</p>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
