import React, { useState, useEffect, useRef } from "react";
import { FaDesktop, FaMoon } from "react-icons/fa";
import { IoSunny } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";

const ToggleTheme = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode === "true") {
      return true;
    } else if (savedDarkMode === "false") {
      return false;
    } else {
      return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
  });

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Ref to detect outside clicks
  const buttonRef = useRef(null); // Ref to detect clicks on the button

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = () => {
      setDarkMode(mediaQuery.matches);
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", darkMode.toString());
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  // Handle clicks outside the theme box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLightMode = () => {
    setDarkMode(false);
    setIsOpen(false); // Close the menu on selection
  };

  const handleDarkMode = () => {
    setDarkMode(true);
    setIsOpen(false); // Close the menu on selection
  };

  const handleSystemDefault = () => {
    setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    setIsOpen(false); // Close the menu on selection
  };

  const appliedIcon = darkMode ? <FaMoon size={16} /> : <IoSunny size={16} />;
  const appliedBgColor = darkMode
    ? "bg-gradient-to-br from-gray-800 to-gray-900"
    : "bg-gradient-to-br from-yellow-400 to-orange-500";

  return (
    <div className="relative z-50">
      {/* Floating active theme button */}
      <motion.button
        ref={buttonRef} // Ref for the button to exclude from outside clicks
        onClick={() => setIsOpen(!isOpen)}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className={`fixed bottom-4 right-4 p-2 ${appliedBgColor} text-white rounded-full shadow-lg focus:outline-none`}
        title="Theme Settings"
      >
        {appliedIcon}
      </motion.button>

      {/* Theme toggle box with Framer Motion animation */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={menuRef} // Ref to the theme box to detect outside clicks
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="fixed bottom-16 right-2 flex flex-col space-y-3 bg-white dark:bg-gray-800 px-2 py-3 rounded-lg shadow-lg"
          >
            {/* Light Mode Button */}
            <motion.button
              onClick={handleLightMode}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="p-2 rounded-full bg-gradient-to-br from-yellow-400 to-orange-500 text-white shadow-lg focus:outline-none hover:shadow-2xl"
              title="Light Mode"
            >
              <IoSunny size={16} />
            </motion.button>

            {/* Dark Mode Button */}
            <motion.button
              onClick={handleDarkMode}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="p-2 rounded-full bg-gradient-to-br from-gray-800 to-gray-900 text-white shadow-lg focus:outline-none hover:shadow-2xl"
              title="Dark Mode"
            >
              <FaMoon size={16} />
            </motion.button>

            {/* System Default Button */}
            <motion.button
              onClick={handleSystemDefault}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="p-2 rounded-full bg-gradient-to-br from-blue-400 to-blue-600 text-white shadow-lg focus:outline-none hover:shadow-2xl"
              title="System Default"
            >
              <FaDesktop size={16} />
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ToggleTheme;
