import { FaLinkedin } from "react-icons/fa";

export const socialMediaLinks = [
  {
    url: "https://www.linkedin.com/company/compcarta-solutions",
    icon: <FaLinkedin />,
  },
  // { url: "https://www.compcarta.com/", icon: <FaGlobe /> },
  // { url: "https://twitter.com/compcarta", icon: <FaXTwitter /> },
  // { url: "https://www.facebook.com/compcarta/", icon: <FaFacebook /> },
  // {
  //   url: "https://www.instagram.com/compcarta",
  //   icon: <FaInstagram />,
  // },
];
