import chip from '../assets/chip1.jpg'
import SCPlogo from '../assets/SCP-logo.png'
import slide3 from '../assets/slide3.jpg'
import EDAlogo from '../assets/EDA-cloud-logo.png'

export const flipBoxData = [
    {
      imageUrlBottom: chip,
      imageUrTop: SCPlogo,
      backContent: 'One of our flagship products is SlideCircuitPro – that seamlessly integrates circuit design capabilities into an existing readily available tool. SlideCircuitPro empowers users to create, visualize, and manipulate digital circuits directly within the familiar environment of the tool. It offers a comprehensive suite of solutions, to cater diverse digital design workflows. SlideCircuitPro is an innovative technology with pending patent approval.',
    },
    {
      imageUrlBottom: slide3,
      imageUrTop: EDAlogo,
      backContent: 'EDAcloud is a cloud-based suite of EDA solutions using open source tools that enables users to execute an industry-standard ASIC design flow. The solution is hosted in the cloud, to keep the cost minimal, reducing the efforts to adapt with open source tools and making sophisticated VLSI design accessible to a broader audience.',
    },
  ];
  