import React from "react";
import { Link } from "react-router-dom";
import {socialMediaLinks} from '.././data/socialMediaData'

const SocialMedia = () => {
  
  return (
    <>
      <div className="flex items-center justify-center space-x-4 pb-4 lg:pb-8">
        {socialMediaLinks.map((item, index) => {
          return (
            <div key={index}>
              <Link to={item.url} target="_blank" rel="noopener noreferrer">
                <div className="flex items-center justify-center space-x-4 hover:scale-125 transition-transform duration-300 bg-background-200 dark:bg-background-800 border border-background-300 dark:border-background-700 hover:text-primary-600 dark:hover:text-primary-300">
                  {item.icon}
                  {index !== socialMediaLinks.length - 1 && (
                    <div className="h-8 border-l border-gray-200" />
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SocialMedia;
