import React from "react";
import SlideUPFImage from "../../../assets/scp/SlideUPF.webp"; // Replace with the actual path to your image
import { useNavigate } from "react-router-dom";

const SlideUPF = () => {
  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/support");
  };

  return (
    <div className="flex flex-col items-center p-4 pt-8 md:p-8 lg:p-12 bg-background-100 dark:bg-background-900 text-text-800 dark:text-text-100">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between my-8 pt-12 max-w-6xl w-full gap-12">
        <div className="space-y-4 self-start md:w-1/2">
          <h1 className="text-4xl font-bold text-primary-800 dark:text-primary-300">
            SlideUPF
          </h1>
          <h2 className="text-xl text-accent-500 dark:text-accent-400">
            Empowering Low-Power Design
          </h2>
          <p className="text-text-600 dark:text-text-400 text-justify">
            Low-power design is essential in today's digital world. SlideUPF
            enhances energy efficiency in digital designs through its specialized
            low-power circuit components and seamless generation of UPF files. Its
            comprehensive suite of tools, including Level Shifters, Isolation Cells,
            Power Domain management tools, and Retention Flip-Flops, enables the
            development of optimized, low-power digital systems.
          </p>
        </div>
        <div className="self-center">
          <img
            src={SlideUPFImage}
            alt="SlideUPF Interface"
            loading="lazy"
            className="rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
      <div className="mt-12 max-w-6xl w-full space-y-4">
        <h3 className="text-2xl font-bold text-primary-800 dark:text-primary-300">
          Features and Benefits
        </h3>
        <ul className="list-decimal list-inside space-y-2 text-justify">
          {[
            "User-Friendly Interface: Utilize a user-friendly graphical interface for intuitive low-power circuit design and UPF generation.",
            "Rich Low Power Components: Its comprehensive suite of tools, including Level Shifters, Isolation Cells, Power Domain management tools, and Retention Flip-Flops, enables the development of optimized, low-power digital systems.",
            "Block Level Power Intent Specification: Define power intent at the block level with SlideUPF’s advanced power management solutions, using optional power ports.",
            "Streamlined Management of Low-Power States: Efficiently manage the low-power states of your digital design using SlideUPF, ensuring effective power management and optimized performance.",
            "Simplified Generation of UPF: Generate UPF (Unified Power Format) files for low-power design description and management.",
          ].map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">{index + 1}.</span>
              <div>
                <strong className="text-primary-700 dark:text-primary-300">
                  {feature.split(":")[0]}:
                </strong>{" "}
                {feature.split(":")[1]}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 flex justify-center">
        <button
          onClick={handleHelpClick}
          className="bg-accent-500 dark:bg-accent-400 text-white py-2 px-4 rounded shadow hover:bg-accent-600 dark:hover:bg-accent-500 transition duration-200"
          aria-label="Get help with SlideUPF"
        >
          Do you need any help on this product?
        </button>
      </div>
    </div>
  );
};

export default SlideUPF;
