import React from "react";
import teamWorking from "../../assets/teamWorking.jpg";
import gropOfPeople from "../../assets/groupOfPeople.jpg";
import ContactCard from "../../components/ContactCard";
import collaborate from "../../assets/collaborate.svg";
import innovate from "../../assets/innovate.svg";
import empower from "../../assets/empower.svg";

const About = () => {
  return (
    <div className="min-h-screen pt-24 py-12 bg-background-50 dark:bg-background-900 text-text-900 dark:text-text-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-primary-500 dark:text-primary-200 text-center">
          About Compcarta Solutions Pvt. Ltd.
        </h2>
        <p className="mt-4 max-w-2xl text-xl text-text-600 dark:text-text-300 lg:mx-auto text-center">
          Welcome to Compcarta, where innovation meets excellence.
        </p>

        <section className="text-text-700 dark:text-text-200">
          <div className="container mx-auto py-16">
            <div className="flex flex-col lg:flex-row items-center">
              <div className="relative top-0 lg:w-1/2 p-4">
                <img
                  src={teamWorking}
                  alt="Team working"
                  className="rounded-lg mb-4 shadow-lg"
                />
                <img
                  src={gropOfPeople}
                  alt="Group of people"
                  width={200}
                  height={200}
                  className="absolute bottom-0 -right-2 xl:right-0 rounded-full border-4 border-background-50 dark:border-background-900"
                />
              </div>
              <div className="lg:w-1/2 p-4 lg:pt-0 text-center lg:text-left">
                <h2 className="text-4xl font-bold text-primary-600 dark:text-primary-300 mb-4">
                  Unlocking The Future Of Technology-Driven Progress
                </h2>
                <p className="text-lg mb-4 text-justify">
                  Welcome to Compcarta Solutions, a pioneering tech startup
                  providing user-friendly Electronic Design Automation (EDA)
                  software solutions. Our goal is to make VLSI design accessible
                  to a wide audience through simple, intuitive, and
                  cost-effective tools.
                </p>
                <p className="text-lg text-justify">
                  Compcarta Solutions is an innovative Electronic Design
                  Automation (EDA) company with a mission to provide VLSI
                  engineers, designers, and students with user-friendly tools
                  that simplify the design and implementation of complex
                  electronic systems.
                </p>
              </div>
            </div>
          </div>

          <hr className="border-t border-text-300 dark:border-text-700" />

          <div className="py-16">
            <div className="container mx-auto text-center">
              <h3 className="text-3xl font-bold text-primary-500 dark:text-primary-300 mb-8">
                Our Motto
              </h3>
              <div className="flex flex-col sm:h-80 lg:h-64 md:flex-row justify-center items-center md:divide-x divide-accent-500">
                <div className="p-4 flex-1 h-full">
                  <div className="text-4xl text-red-500 mb-4 flex justify-center items-center">
                    <img src={innovate} alt="innovate" className="h-12 w-12" />
                  </div>
                  <h4 className="text-xl font-semibold mb-2">Innovate</h4>
                  <p className="text-justify">
                    It represents our relentless pursuit of fresh ideas and
                    transformative solutions in every aspect of our work.
                  </p>
                </div>

                <div className="p-4 flex-1 h-full">
                  <div className="text-4xl text-primary-500 mb-4 flex justify-center items-center">
                    <img
                      src={collaborate}
                      alt="collaborate"
                      className="h-12 w-12"
                    />
                  </div>
                  <h4 className="text-xl font-semibold mb-2">Collaborate</h4>
                  <p className="text-justify">
                    It captures our commitment to engage with our customers to
                    help them achieve their objectives with minimal effort on
                    their part.
                  </p>
                </div>

                <div className="p-4 flex-1 h-full">
                  <div className="text-4xl text-primary-500 mb-4 flex justify-center items-center">
                    <img src={empower} alt="empower" className="h-12 w-12" />
                  </div>
                  <h4 className="text-xl font-semibold mb-2">Empower</h4>
                  <p className="text-justify">
                    It represents our dedication towards empowering
                    professionals and students to excel in the VLSI industry.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-t border-text-300 dark:border-text-700" />

          <div className="container mx-auto py-16">
            <h3 className="text-3xl font-bold text-primary-600 dark:text-primary-300 text-center mb-8">
              What Sets Us Apart?
            </h3>
            <p className="text-xl p-4 text-justify mb-6 text-text-700 dark:text-text-300">
              With a relentless focus on innovation and customer satisfaction,
              our team at Compcarta Solutions thrives to be at the forefront of
              delivering high-quality and efficient solutions for VLSI
              designers.
            </p>
            <ul className="list-disc pl-8 space-y-2 text-lg text-text-600 dark:text-text-400">
              <li>Continuous innovation in a rapidly evolving industry</li>
              <li>Customer-centric approach, prioritizing client feedback</li>
              <li>
                High-quality products adhering to industry standards and
                regulations
              </li>
              <li>Skilled team with effective marketing strategies</li>
              <li>
                Consistent delivery of best-in-class solutions, setting us apart
                from the competition
              </li>
            </ul>
          </div>

          <hr className="border-t border-text-300 dark:border-text-700 pb-16" />

          <div className="ring-1 ring-accent-500 rounded-lg py-8 px-4 flex flex-col md:flex-row justify-around items-center space-y-4">
            <ContactCard />
          </div>
        </section>

        {/* <section className="mt-12">
          <h3 className="text-3xl font-bold text-gray-900">Our Mission</h3>
          <p className="mt-4 text-lg text-gray-600">
            At Compcarta, our mission is to provide cutting-edge solutions that
            drive success and innovation in the tech industry. We are committed
            to delivering exceptional value and unparalleled service to our
            clients.
          </p>
        </section> */}

        {/* <section className="mt-12">
          <h3 className="text-3xl font-bold text-gray-900">Our Vision</h3>
          <p className="mt-4 text-lg text-gray-600">
            Our vision is to be a global leader in technology solutions,
            transforming businesses and improving lives through innovation,
            expertise, and dedication.
          </p>
        </section> */}

        {/* <section className="mt-12">
          <h3 className="text-3xl font-bold text-gray-900">Our Team</h3>
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src="https://via.placeholder.com/150"
                alt="Team Member"
              />
              <h4 className="mt-6 text-lg font-semibold text-gray-900 text-center">
                xyz
              </h4>
              <p className="mt-2 text-gray-600 text-center">CEO</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src="https://via.placeholder.com/150"
                alt="Team Member"
              />
              <h4 className="mt-6 text-lg font-semibold text-gray-900 text-center">
                zyz
              </h4>
              <p className="mt-2 text-gray-600 text-center">CTO</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <img
                className="w-24 h-24 rounded-full mx-auto"
                src="https://via.placeholder.com/150"
                alt="Team Member"
              />
              <h4 className="mt-6 text-lg font-semibold text-gray-900 text-center">
                xyz
              </h4>
              <p className="mt-2 text-gray-600 text-center">CFO</p>
            </div>
          </div>
        </section> */}

        {/* <section className="mt-12">
          <h3 className="text-3xl font-bold text-gray-900">Our History</h3>
          <p className="mt-4 text-lg text-gray-600">
            Founded in 2019, Compcarta started with a vision to revolutionize
            the tech industry. Over the years, we have grown exponentially,
            expanding our services and reaching new heights of success.
          </p>
        </section> */}
      </div>
    </div>
  );
};

export default About;
