import React from "react";
import "./FlipBox.css";

const FlipBox = ({ imageUrlBottom, imageUrltTop, backContent }) => {
  return (
    <div className="flip-box">
      <div className="flip-box-inner">
        <div className="flip-box-front rounded-xl">
          <div id="overlay" className="rounded-xl ">
            <img src={imageUrltTop} alt="Overlay" className="object-cover" />
          </div>
          <img
            src={imageUrlBottom}
            alt="Front"
            className="flip-box-img rounded-xl object-cover brightness-50"
          />
        </div>
        <div className="flip-box-back rounded-xl bg-slate-700 text-white">
          <p className="rounded-xl flip-box-content">{backContent}</p>
        </div>
      </div>
    </div>
  );
};

export default FlipBox;
