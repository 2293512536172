import { GiCircuitry } from "react-icons/gi";
import { FaWaveSquare } from "react-icons/fa";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { SiNationalrail } from "react-icons/si";
import { MdBrokenImage } from "react-icons/md";

export const productsData = [
  {
    id: 1,
    title: "SlideCircuit",
    icon: GiCircuitry,
    color: "bg-gradient-to-r from-[#9945FF] to-[#14F195]",
    hover: "group-hover:bg-[#9945FF]",
    description:
      "Design and customize circuits effortlessly using an existing, easily available tool, leveraging the software's user-friendly interface and powerful capabilities.",

    path: "/products/slidecircuitpro/slidecircuit",
  },
  {
    id: 2,
    title: "SlideWave",
    icon: FaWaveSquare,
    color: "bg-gradient-to-r from-[#FF6B6B] to-[#FFD93D]",
    hover: "group-hover:bg-[#FF6B6B]",
    description:
      "Create timing waveforms with ease, allowing users to visualize and analyze digital signals alongside their circuit designs.",
    path: "/products/slidecircuitpro/slidewave",
  },
  {
    id: 3,
    title: "SlideSystem",
    icon: HiOutlinePresentationChartBar,
    color: "bg-gradient-to-r from-[#0EA5E9] to-[#22D3EE]",
    hover: "group-hover:bg-[#0EA5E9]",
    description:
      "Design and visualize complex digital systems, providing a holistic approach to system-level design and integration.",
    path: "",
  },
  {
    id: 4,
    title: "SlideRTL",
    icon: SiNationalrail,
    color: "bg-gradient-to-r from-[#7928CA] to-[#FF0080]",
    hover: "group-hover:bg-[#FF0080]",
    description:
      "Generate RTL code seamlessly, enabling smooth integration into RTL-based digital design workflows. The RTL code can be generated at the Circuit level or System level.",
    path: "/products/slidecircuitpro/slidertl",
  },
  {
    id: 5,
    title: "SlideUPF",
    icon: MdBrokenImage,
    color: "bg-gradient-to-r from-[#9945FF] to-[#14F195]",
    hover: "group-hover:bg-[#14F195]",
    description:
      "SlideUPF is a valuable extension of the SlideCircuit capabilities dedicated to low-power digital circuit design and UPF generation.",
    path: "/products/slidecircuitpro/slideupf",
  },
];

export const keyFeatures = [
  "Presentation Tool Integration",
  "VLSI Design Expertise",
  "User-Centric Approach",
  "Enhanced Productivity",
];

export const featuresData = [
  "Integration into an existing easily available tool: Seamlessly integrates into an existing easily available tool.",
  "VLSI Design Expertise: Specifically designed for VLSI complexities, it goes beyond being a mere presentation tool by seamlessly incorporating VLSI design flow.",
  "User-Friendly Interface: Features an intuitive drag-and-drop interface for easy circuit design.",
  "Rich Component Libraries: Offers an extensive library of pre-designed components, including combinational circuits, sequential circuits, Clock Domain Circuits, and Low power circuits.",
  "User library support: Easily export custom-designed components to a personal library for future use.",
  "Customization and Flexibility: Allows extensive customization to suit various circuit design and visualization needs.",
  "Comprehensive Solutions: Offers a complete suite of solutions, covering Circuit Design (SlideCircuit), System Design (SlideSystem), Waveform Creation (SlideWave), RTL Design (SlideRTL), as well as Low Power Design and UPF Generation (SlideUPF).",
];
