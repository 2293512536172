export const features = [
    {
      id: 1,
      name: "SlideCircuit",
      description: "Circuit Design Tool",
      price: 99,
      url: "https://slidecircuitpro.com/product/slidecircuit/",
      imageUrl: "https://via.placeholder.com/50?text=SlideCircuit",
      trialDuration: "14 days of full access",
      features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
      ],
    },
    {
      id: 2,
      name: "SlideWave",
      description: "Waveform Design Tool",
      price: 149,
      url: "https://slidecircuitpro.com/product/slidewave/",
      imageUrl: "https://via.placeholder.com/50?text=SlideWave",
      trialDuration: "14 days of limited access",
      features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
      ],
    },
    {
      id: 3,
      name: "SlideRTL",
      description: "RTL Code Generation Tool",
      price: 249,
      url: "https://slidecircuitpro.com/product/slidertl/",
      imageUrl: "https://via.placeholder.com/50?text=SlideRTL",
      trialDuration: "14 days of full access",
      features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
      ],
    },
    {
      id: 4,
      name: "SlideSystem",
      description: "System Design Tool",
      price: 299,
      url: "https://slidecircuitpro.com/product/slidesystem/",
      imageUrl: "https://via.placeholder.com/50?text=SlideSystem",
      trialDuration: "14 days of full access",
      features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
      ],
    },
    {
      id: 5,
      name: "SlideUPF",
      description: "UPF Design Tool",
      price: 349,
      url: "https://slidecircuitpro.com/product/slideupf/",
      imageUrl: "https://via.placeholder.com/50?text=SlideUPF",
      trialDuration: "14 days of limited access",
      features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
      ],
    },
  ];
  