import React from "react";
import SlideCircuitImage from "../../../assets/scp/SlideCircuit.webp";
import { useNavigate } from "react-router-dom";

const SlideCircuit = () => {
  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/support");
  };

  return (
    <div className="flex flex-col items-center p-4 pt-8 md:p-8 lg:p-12 bg-background-100 dark:bg-background-900 text-text-800 dark:text-text-100">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between my-8 pt-12 max-w-6xl w-full gap-12">
        <div className="space-y-4 self-start md:w-1/2">
          <h1 className="text-4xl font-bold text-primary-800 dark:text-primary-300">
            SlideCircuit
          </h1>
          <h2 className="text-xl text-accent-500 dark:text-accent-400">
            Circuit Design Simplified
          </h2>
          <p className="text-text-600 dark:text-text-400 text-justify">
            SlideCircuit empowers users to create, visualize, and manipulate
            digital circuits effortlessly. With its user-friendly interface and
            powerful functionalities, SlideCircuit revolutionizes the way
            digital circuit design is approached and executed.
          </p>
        </div>
        <div className="self-center">
          <img
            src={SlideCircuitImage}
            alt="SlideCircuit Interface"
            loading="lazy"
            className="rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          />
        </div>
      </div>
      <div className="mt-12 max-w-6xl w-full space-y-4">
        <h3 className="text-2xl font-bold text-primary-800 dark:text-primary-300">
          Features and Benefits
        </h3>
        <ul className="list-decimal list-inside space-y-2 text-justify">
          {[
            "Rich Component Libraries: An extensive collection of ready-made components for combinational circuits, sequential circuits, Clock Domain Circuits, and Low power circuits.",
            "Bridging the Hardware Mindset and Circuit Solutions: Aligns with designers’ hardware-oriented thinking, facilitating a smooth transition from ideas to practical circuit solutions.",
            "One Circuit, Multiple Applications: The created circuit serves multiple purposes, including brainstorming, architecture design, documentation, RTL Design, and low power (UPF) generation.",
            "Customization and Flexibility: Extensive customization options to accommodate various circuit design and visualization needs.",
            "Enhanced Productivity: Improved efficiency and productivity in circuit design tasks.",
            "Time Efficiency: Speeds up design work with an intuitive interface and integrated functions.",
            "Low Learning Curve: Easy to adopt with a low learning curve.",
          ].map((feature, index) => (
            <li key={index} className="flex items-start">
              <span className="mr-2">{index + 1}.</span>
              <div>
                <strong className="text-primary-700 dark:text-primary-300">
                  {feature.split(":")[0]}:
                </strong>{" "}
                {feature.split(":")[1]}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-8 flex justify-center">
        <button
          onClick={handleHelpClick}
          className="bg-accent-500 dark:bg-accent-400 text-white py-2 px-4 rounded shadow hover:bg-accent-600 dark:hover:bg-accent-500 transition duration-200"
          aria-label="Get help with SlideCircuit"
        >
          Do you need any help with this product?
        </button>
      </div>
    </div>
  );
};

export default SlideCircuit;
