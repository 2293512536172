import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { AiOutlineOrderedList } from "react-icons/ai";

import {
  FaUserAlt,
  FaUserShield,
  FaMapMarkerAlt,
  FaHeadset,
} from "react-icons/fa";
import { MdCloudDownload, MdAdd } from "react-icons/md";

const UserDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const menuItems = [
    { name: "Basic Info", icon: <FaUserAlt />, link: "/account/basic-info" },
    {
      name: "Account",
      icon: <FaUserShield />,
      link: "/account/account-details",
    },
    { name: "Orders", icon: <AiOutlineOrderedList />, link: "/account/orders" },
    { name: "Addresses", icon: <FaMapMarkerAlt />, link: "/account/addresses" },
    {
      name: "Downloads",
      icon: <MdCloudDownload />,
      link: "/account/downloads",
    },
    {
      name: "Support Tickets",
      icon: <FaHeadset />,
      link: "/account/support-tickets",
    },
    {
      name: "Submit New Ticket",
      icon: <MdAdd />,
      link: "/account/submit-new-ticket",
    },
  ];

  return (
    <div className="flex pt-16 h-screen">
      {/* Sidebar */}
      <div
        className={`${
          isSidebarOpen ? "block" : "hidden"
        } fixed z-19 inset-0 bg-black opacity-50 transition-opacity lg:hidden`}
        onClick={() => setIsSidebarOpen(false)}
      ></div>
      <aside
        className={`absolute inset-0 w-64 z-19 bg-white border-r overflow-y-auto transform ${
          isSidebarOpen ? "translate-x-0 ease-out" : "-translate-x-full ease-in"
        } transition-transform duration-300 lg:translate-x-0 lg:static lg:inset-0`}
      >
        <div className="flex items-center justify-center mt-8">
          <h1 className="text-2xl text-center font-semibold text-gray-800">
            User Dashboard
          </h1>
        </div>
        <nav className="mt-10">
          {menuItems.map((item, index) => (
            <div key={index}>
              <Link
                to={item.link}
                className={`flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform hover:bg-gray-200 hover:text-gray-700`}
                onClick={() => setIsSidebarOpen(false)}
              >
                <span className="text-2xl">{item.icon}</span>
                <span className="mx-4 font-medium">{item.name}</span>
              </Link>
            </div>
          ))}
          <hr className="mt-4" />
          <div className="flex flex-col">
            <Link
              to="/logout"
              className="flex items-center font-medium px-4 py-2 mt-5 text-gray-600 transition-colors duration-300 transform hover:bg-gray-200 hover:text-gray-700"
            >
              <MdLogout className="text-2xl mr-2" />
              Logout
            </Link>
          </div>
        </nav>
      </aside>

      {/* Main content area */}
      <div className="flex-1 flex flex-col">
        <header className="flex justify-between items-center bg-white py-2 px-4 border-b lg:hidden">
          <button
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            className="text-gray-500 focus:outline-none focus:text-gray-600"
          >
            <FaBars className="w-6 h-6" />
          </button>
          {/* <h1 className="text-xl font-semibold text-gray-800">
            User Dashboard
          </h1> */}
        </header>
        <main className="flex-1 p-6 overflow-y-auto">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default UserDashboard;
