import React from "react";

const SoftwareLicenseAgreement = () => {
  return (
    <div className="p-4 pt-16 md:p-8 lg:p-12 flex flex-col items-center justify-center bg-background-100 dark:bg-background-900 text-text-900 dark:text-text-200">
      <header className="text-center my-8">
        <h1 className="text-4xl font-extrabold underline text-primary-500 dark:text-primary-400">
          SlideCircuitPro Software License Agreement
        </h1>
      </header>
      <div className="max-w-6xl w-full">
        <p className="mt-4 text-justify text-text-700 dark:text-text-300">
          This Software License Agreement (“Agreement”) is a legally binding
          agreement between Compcarta Solutions Private Limited (“Compcarta”)
          and your organization (“Customer”), collectively referred to as the
          “Parties.” By downloading, installing, or using the SlideCircuitPro
          software (“Software”), Customer agrees to be bound by the terms and
          conditions of this Agreement. If Customer does not agree to these
          terms, do not use the Software.
        </p>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            1. License Grant
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Compcarta grants Customer a non-exclusive, non-transferable,
            revocable license to use the Software for internal business
            purposes, subject to the terms of this Agreement. This license is
            granted solely to Customer and its authorized employees or agents.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            2. Subscription-Based License
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            The Software is provided on a subscription basis. Customer is
            required to pay the applicable subscription fees in accordance with
            the pricing and terms specified by Compcarta. Failure to pay the
            subscription fees may result in the suspension or termination of
            Customer’s license.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            3. Installation and Usage
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Customer may install and use the Software on computers and devices
            within Customer's organization for which valid host keys and
            approved licenses have been obtained from Compcarta. This license is
            locked to a particular host.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            4. Restrictions
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            Customer may not:
          </p>
          <ul className="list-disc list-inside mt-4 space-y-2 text-text-700 dark:text-text-300">
            <li>
              Copy, modify, or distribute the Software except as expressly
              provided in this Agreement.
            </li>
            <li>Reverse engineer, decompile, or disassemble the Software.</li>
            <li>
              Transfer, sublicense, or assign your license to another party.
            </li>
            <li>
              Use the Software for any illegal, unauthorized, or unethical
              purpose.
            </li>
          </ul>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            5. Intellectual Property
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            The Software and all related intellectual property rights are and
            will remain the property of Compcarta. Customer does not acquire any
            ownership rights to the Software.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            6. Warranty
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            The Software is provided “as is” without warranties of any kind,
            either express or implied. Compcarta does not warrant that the
            Software will be error-free or meet Customer’s specific
            requirements.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            7. Limitation of Liability
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            In no event shall Compcarta be liable for any damages, including but
            not limited to direct, indirect, special, incidental, or
            consequential damages, arising out of the use or inability to use
            the Software.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            8. Termination
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            This Agreement is effective until terminated. Compcarta may
            terminate Customer's license at any time if Customer breaches any of
            the terms and conditions of this Agreement. Upon termination,
            Customer must cease using the Software and remove all copies of the
            Software from its organization’s devices.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            9. Governing Law
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            This Agreement shall be governed by and construed in accordance with
            the laws of [Your Jurisdiction]. Any disputes arising under or in
            connection with this Agreement shall be subject to the exclusive
            jurisdiction of the courts of Gautam Buddha Nagar, Uttar Pradesh,
            India.
          </p>
        </section>
        <section className="mt-8">
          <h2 className="text-2xl font-bold text-primary-700 dark:text-primary-500">
            10. Entire Agreement
          </h2>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            This Agreement constitutes the entire agreement between the Parties
            regarding the Software and supersedes all prior and contemporaneous
            agreements and understandings, whether oral or written.
          </p>
        </section>
        <section className="mt-8 text-gray-800 font-semibold">
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
            By using the Software, Customer acknowledges that it has read and
            understood this Agreement and agrees to be bound by its terms and
            conditions.
          </p>
          <p className="mt-4 text-justify text-text-700 dark:text-text-300">
          Compcarta Solutions Pvt. Ltd.
            <br />
            A-50, Ecotech - VI, Greater Noida, U.P. 201310
            <br />
            02 Oct, 2023
          </p>
        </section>
      </div>
    </div>
  );
};

export default SoftwareLicenseAgreement;
