import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const EditableField = ({
  label,
  value,
  name,
  onEdit,
  onSave,
  editMode,
  onChange,
}) => {
  return (
    <div className="">
      <label className="block text-gray-700">{label}</label>
      {editMode ? (
        <div className="flex gap-4">
          <input
            type="text"
            name={name}
            defaultValue={value}
            className="mt-2 p-2 border rounded-md w-full"
            onChange={onChange}
          />
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded"
            onClick={onSave}
          >
            Save
          </button>
          <button className="text-blue-500 hover:underline" onClick={onEdit}>
            Cancel
          </button>
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <input
            type="text"
            value={value}
            className="mt-2 p-2 border rounded-md w-full"
            readOnly
          />
          <button
            className="text-blue-500 hover:underline ml-4"
            onClick={onEdit}
          >
            Edit
          </button>
        </div>
      )}
    </div>
  );
};

const BasicInfo = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;
  const user = useSelector((state) => state.auth.user);
  const [editMode, setEditMode] = useState({
    name: false,
    username: false,
    email: false,
    phone: false,
    gender: false,
    userType: false,
    country: false,
  });
  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    username: user.username,
    email: user.email,
    phone: user.phone,
    gender: user.gender,
    userType: user.userType,
    country: user.country,
  });

  const handleEditToggle = (field) => {
    setEditMode((prevState) => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSaveChanges = async (field) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${apiURL}/api/user`,
        { ...userData },
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      // Handle response, e.g., update Redux state or local state
      console.log("User info updated:", response.data);
      handleEditToggle(field);
    } catch (err) {
      console.error("Error updating user info:", err.response.data);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Basic Information</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <EditableField
          label="Name"
          value={`${userData.firstName} ${userData.lastName}`}
          name="firstName"
          onEdit={() => handleEditToggle("name")}
          onSave={() => handleSaveChanges("name")}
          editMode={editMode.name}
          onChange={handleInputChange}
        />
        <EditableField
          label="Username"
          value={userData.username}
          name="username"
          onEdit={() => handleEditToggle("username")}
          onSave={() => handleSaveChanges("username")}
          editMode={editMode.username}
          onChange={handleInputChange}
        />
        <EditableField
          label="Email"
          value={userData.email}
          name="email"
          onEdit={() => handleEditToggle("email")}
          onSave={() => handleSaveChanges("email")}
          editMode={editMode.email}
          onChange={handleInputChange}
        />
        <EditableField
          label="Phone Number"
          value={userData.phone}
          name="phone"
          onEdit={() => handleEditToggle("phone")}
          onSave={() => handleSaveChanges("phone")}
          editMode={editMode.phone}
          onChange={handleInputChange}
        />
        <EditableField
          label="Gender"
          value={userData.gender}
          name="gender"
          onEdit={() => handleEditToggle("gender")}
          onSave={() => handleSaveChanges("gender")}
          editMode={editMode.gender}
          onChange={handleInputChange}
        />
        <EditableField
          label="User Type"
          value={userData.userType}
          name="userType"
          onEdit={() => handleEditToggle("userType")}
          onSave={() => handleSaveChanges("userType")}
          editMode={editMode.userType}
          onChange={handleInputChange}
        />
        <EditableField
          label="Country"
          value={userData.country}
          name="country"
          onEdit={() => handleEditToggle("country")}
          onSave={() => handleSaveChanges("country")}
          editMode={editMode.country}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default BasicInfo;