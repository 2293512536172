import { FaMicrochip, FaGraduationCap } from "react-icons/fa";

export const homeCardData = [
  {
    id: 1,
    title: "Semiconductor",
    icon: FaMicrochip,
    description:
      "Driving tech innovation, optimizing workflows, expediting common tasks ultimately allowing professionals to allocate their time more effectively and focus on higher-value activities.",
    color: "bg-gradient-to-r from-[#FF6B6B] to-[#FFD93D]",
  },
  {
    id: 2,
    title: "Education",
    icon: FaGraduationCap,
    description:
      "Transform the learning experience with our practical solutions bridging the gap between academics and the VLSI industry and offer the trained students a jump start into the VLSI industry.",
    color: "bg-gradient-to-r from-[#7928CA] to-[#FF0080]",
  },
];
