import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { motion } from "framer-motion";
import axios from "axios";
import moment from "moment";
import { logoBase64 } from "../../data/receiptData";

const Receipt = () => {
  const apiURL = process.env.REACT_APP_API_BASE_URL;

  const { state } = useLocation();
  const {
    selectedFeatures,
    totalPrice,
    paymentMethod,
    customerName,
    customerEmail,
    hostInfo,
    featureDates,
    receiptNumber = "#XXXXXXXX",
    invoiceNumber = "#XXXXXXXX",
    taxAmount = "67",
    additionalCharges = "50",
    taxLabel = "Tax",
    additionalChargesLabel = "Other charges",
  } = state;

  const [message, setMessage] = useState("");

  const logo = logoBase64;

  // Function to generate the PDF
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add Company Logo
    doc.addImage(logo, "PNG", 10, 5, 30, 30);

    // Add Company Name in the Middle
    doc.setFontSize(18);
    doc.setFont("times", "bold");
    doc.setTextColor(0, 51, 102);
    doc.text("Compcarta Solutions Pvt. Ltd.", 100, 20, { align: "center" });

    // Add Download Date and Time on the Right
    const currentDate = moment();
    const dateStr = currentDate.format("DD-MM-YYYY");
    const timeStr = currentDate.format("hh:mm:ss A");
    doc.setFontSize(12);
    doc.setFont("courier", "bold");
    doc.setTextColor(0, 102, 204);

    // Align Receipt Number, Invoice Number, and Date/Time
    doc.text(`Receipt No: ${receiptNumber}`, 200, 10, { align: "right" });
    doc.text(`Invoice No: ${invoiceNumber}`, 200, 15, { align: "right" });
    doc.text(`Date: ${dateStr}`, 200, 20, { align: "right" });
    doc.text(`Time: ${timeStr}`, 200, 25, { align: "right" });

    // Add a Horizontal Line
    doc.setLineWidth(0.5);
    doc.setDrawColor(0, 51, 102);
    doc.line(10, 35, 200, 35);

    // Add Receipt Title
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.setTextColor(0, 51, 102);
    doc.text("Invoice Details", 105, 45, { align: "center" });
    doc.setLineWidth(0.8);
    doc.setDrawColor(0, 51, 102);
    doc.line(85, 47, 125, 47);

    // Add Company and Buyer Information in a Row
    const companyInfoY = 55;
    const buyerInfoY = companyInfoY;

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 0);

    // Company Info (left column)
    const companyInfoX = 10;
    doc.text("Company Information:", companyInfoX, companyInfoY);
    doc.text("Compcarta Solutions Pvt. Ltd.,", companyInfoX, companyInfoY + 10);
    doc.text(
      "A-50, Ecotech-6, Greater Noida, U.P, 201310 - INDIA",
      companyInfoX,
      companyInfoY + 20
    );
    doc.setFont("courier", "normal");
    doc.text("GST No: 09AAICC1554A1Z1", companyInfoX, companyInfoY + 30);

    // Buyer Info (right column)
    const buyerInfoX = 110;
    doc.setFont("helvetica", "normal");
    doc.text("Buyer Information:", buyerInfoX, buyerInfoY);
    doc.text(`Name: ${customerName}`, buyerInfoX, buyerInfoY + 10);
    doc.text(`Email: ${customerEmail}`, buyerInfoX, buyerInfoY + 20);
    doc.text(`Payment Method: ${paymentMethod}`, buyerInfoX, buyerInfoY + 30);
    doc.text(`Host Info: ${hostInfo}`, buyerInfoX, buyerInfoY + 40);

    // Add a horizontal line below company and buyer info
    // doc.setLineWidth(0.5);
    // doc.setDrawColor(0, 51, 102);
    // doc.line(10, buyerInfoY + 50, 200, buyerInfoY + 50);

    // Add Features Purchased Title
    const featuresY = buyerInfoY + 50;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0, 51, 102);
    doc.text("Features Purchased:", 10, featuresY);

    // Define table columns
    const columns = [
      { title: "S.No", dataKey: "serial" },
      { title: "Feature", dataKey: "name" },
      { title: "Description", dataKey: "description" },
      { title: "License Type", dataKey: "licenseType" },
      { title: "Enabled", dataKey: "enabled" },
      { title: "Start Date", dataKey: "startDate" },
      { title: "Expiry Date", dataKey: "expiryDate" },
      { title: "Price (Rs.)", dataKey: "price" },
    ];

    // Define table data with serial numbers
    const rows = selectedFeatures.map((feature, index) => ({
      serial: index + 1,
      name: feature.name,
      description: feature.description,
      price: `Rs. ${feature.price}`,
      startDate: moment(featureDates[feature.id].startDate).format(
        "DD-MM-YYYY"
      ),
      expiryDate: moment(featureDates[feature.id].expiryDate).format(
        "DD-MM-YYYY"
      ),
      licenseType: featureDates[feature.id].licenseType,
      enabled: featureDates[feature.id].enabled ? "Yes" : "No",
    }));

    // Add the table
    doc.autoTable({
      head: [columns.map((col) => col.title)],
      body: rows.map((row) => columns.map((col) => row[col.dataKey])),
      startY: featuresY + 10,
      theme: "grid",
      styles: { font: "courier", fontSize: 10, textColor: [0, 0, 0] },
      bodyStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
      alternateRowStyles: { fillColor: [242, 242, 242] },
    });

    // Add Prices Table
    const finalY = doc.lastAutoTable.finalY + 5;

    // Define columns and rows for the price table
    const priceColumns = [
      { title: "Label", dataKey: "label" },
      { title: "Amount", dataKey: "amount" },
    ];
    const priceRows = [
      { label: "Total Price:", amount: `Rs. ${totalPrice}` },
      { label: taxLabel + ":", amount: `Rs. ${taxAmount}` },
      {
        label: additionalChargesLabel + ":",
        amount: `Rs. ${additionalCharges}`,
      },
      {
        label: "Grand Total:",
        amount: `Rs. ${
          parseFloat(totalPrice) +
          parseFloat(taxAmount) +
          parseFloat(additionalCharges)
        }`,
      },
    ];

    // Add the price table
    doc.autoTable({
      body: priceRows.map((row) => priceColumns.map((col) => row[col.dataKey])),
      headerStyles: { cellWidth: "wrap" },
      startY: finalY,
      theme: "grid",
      styles: {
        font: "Helvetica",
        fontSize: 10,
        textColor: [0, 0, 0],
        cellPadding: 2,
      },
      columnStyles: {
        0: { cellWidth: 40, halign: "right" }, // Label column width adjusted to content
        1: { cellWidth: 30, halign: "left" }, // Amount column width adjusted to content
      },
      margin: { left: 126 }, // Add left margin to position the table
      didParseCell: function (data) {
        // Apply styles to the first row
        if (data.row.index === 0) {
          data.cell.styles.fontSize = 10; // Font size for the first row
          data.cell.styles.textColor = [0, 0, 0]; // Red color for the first row
        }
        // Apply styles to the last row
        if (data.row.index === priceRows.length - 1) {
          data.cell.styles.fontSize = 12; // Font size for the last row
          data.cell.styles.textColor = [0, 0, 0]; // Green color for the last row
          data.cell.styles.fontStyle = "bold"; // Green color for the last row
        }
      },
    });

    // Add Thank You Note and Support Information
    const finalNoteY = doc.lastAutoTable.finalY + 20;
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.setTextColor(0, 51, 102);
    doc.text("Thank you for your purchase!", 10, finalNoteY);

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.setTextColor(128, 128, 128);
    doc.text(
      "For support, visit: https://compcarta.com/support",
      10,
      finalNoteY + 10
    );
    doc.text("Contact us at: info@compcarta.com", 10, finalNoteY + 20);
    doc.text(
      "Follow us on social media: https://in.linkedin.com/company/compcarta-solutions",
      10,
      finalNoteY + 30
    );

    return doc;
  };

  // Function to download the PDF
  const downloadReceipt = () => {
    const doc = generatePDF();
    doc.save(`SlideCircuitPro_Receipt_${customerName}.pdf`);
  };

  // Function to generate the PDF and return it as a base64 string
  const generatePDFBase64 = () => {
    const doc = generatePDF();
    return doc.output("datauristring"); // Return PDF as base64 string
  };

  const handlePurchase = async () => {
    const pdfBase64 = generatePDFBase64();
    const purchaseData = {
      customerName,
      customerEmail,
      selectedFeatures,
      totalPrice,
      pdf: pdfBase64,
      paymentMethod,
    };
    try {
      const response = await axios.post(`${apiURL}/api/license/purchase`, purchaseData);
      setMessage(response.data.message);
    } catch (error) {
      console.error("Error during purchase:", error);
      setMessage("Purchase failed. Please try again.");
    }
  };

  return (
    <motion.div
      className="container mx-auto py-8 pt-16"
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.3 }}
    >
      <h2 className="text-3xl font-bold mb-6 text-center">Receipt</h2>
      <div className="flex justify-center">
        <button
          onClick={downloadReceipt}
          className="mt-4 px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-yellow-500 to-orange-500 text-white hover:bg-white hover:text-black hover:scale-105"
        >
          Download Receipt
        </button>
        <button
          onClick={handlePurchase}
          className="mt-4 px-6 py-3 font-semibold rounded-lg bg-gradient-to-br from-green-500 to-blue-500 text-white hover:bg-white hover:text-black hover:scale-105"
        >
          Confirm Purchase
        </button>
      </div>
      {message && <p className="text-center text-red-600 mt-4">{message}</p>}
    </motion.div>
  );
};

export default Receipt;
