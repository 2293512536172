import slide1 from '../assets/slide1.jpg';
import slide2 from '../assets/slide2.jpg';
import slide3 from '../assets/slide3.jpg';
export const slides = [
  {
    id: "slide1",
    imgUrl: slide1,
    title: "Compcarta Solutions",
    description: "EDA for an effortless and affordable tomorrow",
    link: "company",
  },
  {
    id: "slide2",
    imgUrl: slide2,
    title: "SlideCircuitPro",
    description: "Simplified VLSI Solutions",
    link: "products/slidecircuitpro",
  },
  {
    id: "slide3",
    imgUrl: slide3,
    title: "EDAcloud",
    description: "EDA tools within reach for all",
    link: "products/eda-cloud",
  },
];
